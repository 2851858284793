import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../../Service/LanguageService';

import { InformationBoardData, InformationBoardList, InformationBoardDataHeader } from '../../../Models/InformationBoardModel';

import { HttpClient } from '@angular/common/http';
import { Caption, PortalLanguageCaption } from '../../../LanguageModel/Caption';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { InformationBoardService } from '../../../Lecturer/Service/InformationBoardService';
import { SharedService } from '../../../Lecturer/Service/SharedService';

@Component({
    selector: 'ViewNews',
    templateUrl: './ViewNews.html',
    styleUrls: ['./ViewNews.css']
})
/** ViewNews component*/
export class ViewNewsComponent {
/** ViewNews ctor */
  public informationHeader: InformationBoardDataHeader;
  public captions: Caption;
  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<ViewNewsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InformationBoardList,
    private activatedRoute: ActivatedRoute, private route: ActivatedRoute,

    private router: Router,
    public languageService: LanguageService,
    public informationBoardService: InformationBoardService,
    public sharedService: SharedService,
    private http: HttpClient,
    private location: Location,
    private fb: FormBuilder) {
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];
    this.informationBoardService.GetInfoDataUserView(data.id).then(e => { this.informationHeader = e; });
    
  }

  DownloadDocument(fileId: string) {
    this.sharedService.downloadFile(fileId);
  }
  BehavRet(image: string): BehaviorSubject<string> {
    return new BehaviorSubject(image);
  }
}

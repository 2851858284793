<div class="_cs_size-w-percent--100 _cs_p-left--lg _cs_p-right--lg">

</div>


<div class="_cs_flex _cs_size-w-percent--100">
  <div class="_cs_size-w-percent--100 _cs_p-left--lg _cs_p-right--lg _cs_p-down--lg">
    <div class="_cs_size-h-percent--100 sidebarbg _cs_flex">

      <div class="sidebarbg__wrapper _cs_p-left--none-i _cs_size-w-percent--100 _cs_flex _cs_flex-d--column">
        <div class="_cs_m-down--md _cs_m-top--xxl">
          <div class="_cs_p-xs _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_border-down--primary-6 _cs_flex _cs_flex-a--center">
            <span class="_cs_icon _cs_icon-xl _cs_shadow-primary-6-0--2 _cs_border-radius--full _cs_border--primary-6 _cs_m-right--md">
              <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-curriculum"></span>
            </span> <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold
                        _cs_color-primary-6">{{captions.schedule}}</span>
          </div>
        </div>
        <div class="_cs_flex" *ngIf="captions">
          <div class="col-lg-12 content-wrapper">
            <ejs-schedule #schedule height='750px' [locale]="language==1?'en-GB':'en'" width="100%" startHour="09:00" [(selectedDate)]="selectedDate" [eventSettings]="eventSettings" queryCellInfo="colorChange" (eventRendered)="onEventRendered($event)" dateFormat="yyyy-dd-MM">
              <ng-template #timeScaleMajorSlotTemplate let-data>
                <div class="majorTime">{{getMajorTime(data.date)}}</div>
              </ng-template>
              <!--<ng-template #timeScaleMinorSlotTemplate let-data>[timeScale]="timeScale"
          <div class="minorTime">{{getMinorTime(data.date)}}</div>
        </ng-template>-->
            </ejs-schedule>
          </div>
        </div>
        <!--<table class="table table-bordered  " *ngIf="timeTableOut">
          <tr *ngFor="let t of weekDayList">
            <td>{{t}}</td>
            <ng-container *ngFor="let tm of timeTableOut">
              <td *ngIf="t==tm.dayName" [ngClass]="{'equalTime': tm.timeEquals===true}">
                <div>
                  <div class="_cs_flex _cs_flex-a--center _cs_m-down--sm">
                    <div class="_cs_border-radius--sm _cs_p-sm _cs_opacity-bg-primary-6-0--1">
                      <span class="_cs_label _cs_label-md _cs_color-primary-2">სასწავლო კურსი: </span>
                    </div>
                    <div class="_cs_border-down--primary-3  _cs_p-sm">
                      <span class=" _cs_label _cs_label-sm _cs_color-primary-2">{{tm.syllabusName}}</span>
                    </div>
                  </div>
                  <div class="_cs_flex _cs_flex-a--center _cs_m-down--sm">
                    <div class="_cs_border-radius--sm _cs_p-sm _cs_opacity-bg-primary-6-0--1">
                      <span class="_cs_label _cs_label-md _cs_color-primary-2">სალექციო ტიპი: </span>
                    </div>
                    <div class="_cs_border-down--primary-3  _cs_p-sm">
                      <span class=" _cs_label _cs_label-sm _cs_color-primary-2">{{tm.lectureTypeName}}</span>
                    </div>
                  </div>
                  <div class="_cs_flex _cs_flex-a--center _cs_m-down--sm">
                    <div class="_cs_border-radius--sm _cs_p-sm _cs_opacity-bg-primary-6-0--1">
                      <span class="_cs_label _cs_label-md _cs_color-primary-2">საფეხური: </span>
                    </div>
                    <div class="_cs_border-down--primary-3  _cs_p-sm">
                      <span class=" _cs_label _cs_label-sm _cs_color-primary-2">{{tm.studyLevel}}</span>
                    </div>
                  </div>
                  <div class="_cs_flex _cs_flex-a--center _cs_m-down--sm">
                    <div class="_cs_border-radius--sm _cs_p-sm _cs_opacity-bg-primary-6-0--1">
                      <span class="_cs_label _cs_label-md _cs_color-primary-2">აუდიტორია: </span>
                    </div>
                    <div class="_cs_border-down--primary-3  _cs_p-sm">
                      <span class=" _cs_label _cs_label-sm _cs_color-primary-2">{{ClassRoomRootShow(tm.classRoomsId)}},</span>
                    </div>
                  </div>
                  <div *ngIf="tm.date" class="_cs_flex _cs_flex-a--center _cs_m-down--sm">
                    <div class="_cs_border-radius--sm _cs_p-sm _cs_opacity-bg-primary-6-0--1">
                      <span class="_cs_label _cs_label-md _cs_color-primary-2">თარიღი: </span>
                    </div>
                    <div class="_cs_border-down--primary-3  _cs_p-sm">
                      <span class=" _cs_label _cs_label-sm _cs_color-primary-2">{{tm.date}}</span>
                    </div>
                  </div>
                  <div *ngIf="tm.date" class="_cs_flex _cs_flex-a--center _cs_m-down--sm">
                    <div class="_cs_border-radius--sm _cs_p-sm _cs_opacity-bg-primary-6-0--1">
                      <span class="_cs_label _cs_label-md _cs_color-primary-2">დაწყების დრო: </span>
                    </div>
                    <div class="_cs_border-down--primary-3  _cs_p-sm">
                      <span class=" _cs_label _cs_label-sm _cs_color-primary-2">{{tm.startTime}}</span>
                    </div>
                  </div>
                  <div *ngIf="tm.date" class="_cs_flex _cs_flex-a--center _cs_m-down--sm">
                    <div class="_cs_border-radius--sm _cs_p-sm _cs_opacity-bg-primary-6-0--1">
                      <span class="_cs_label _cs_label-md _cs_color-primary-2">დასრულების დრო: </span>
                    </div>
                    <div class="_cs_border-down--primary-3  _cs_p-sm">
                      <span class=" _cs_label _cs_label-sm _cs_color-primary-2">{{tm.endTime}}</span>
                    </div>
                  </div>

                </div>
              </td>
            </ng-container>
          </tr>
        </table>-->
      </div>

    </div>
  </div>
</div>

import { Component, Inject, EventEmitter, Injectable, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder } from '@angular/forms';
import { UserService } from '../_services/user.service';
import { ChangePasswordData } from '../../Models/Profile';
import { Caption, PortalLanguageCaption } from '../../LanguageModel/Caption';

@Component({
    selector: 'changePassword',
    templateUrl: './ChangePassword.html',
    styleUrls: ['./ChangePassword.css']
})
/** ChangePassword component*/
export class ChangePassword {
  /** ChangePassword ctor */
  public currentPassword: string;
  public newPassword: string;
  public confirmPassword: string;
  public captions: Caption;

  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChangePassword>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private activatedRoute: ActivatedRoute,
    //private SharedService: SharedService,
    private route: ActivatedRoute,
    private location: Location,
    private userService: UserService,
    private router: Router, private fb: FormBuilder) {
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];

  }

  ChangePassword(): void {
    if (this.confirmPassword == this.newPassword) {
      let psChange = new ChangePasswordData();
      psChange.currentPassword = this.currentPassword;
      psChange.newPassword = this.confirmPassword;
      this.userService.changePassword(psChange).then(e => {
        if (e) {
          alert("წარმატებით შეიცვალა!");
        }
        else {
          alert("მოხდა შეცდომა!");
        }
      });
      this.dialogRef.close();
    }
    else {
      alert("არ ემთხვევა!");
    }
  }
}

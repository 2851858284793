export class InformationBoardList {
  public id: number;
  public infoTypeId: number;
  public statusId: number;
  public headerText: string | null;
  public shortText: string | null;
  public linkUrl: string | null;
  public infoTypeCaption: string | null;
  public statusCaption: string | null;
  public publishDateTime: Date;
  public informationBoardDataFiles: Array<InformationBoardDataFile>
}
export class InformationBoardResult {
  public informationBoardList: Array<InformationBoardList>
  public count: number;
}

export class Infofilter {
  public infoTypes: number[] | null;
  public header: string | null;
  public fullText: string | null;
  public shortText: string | null;
  public publishDateFrom: Date | null;
  public publishDateTo: Date | null;
  public statusId: number;
  public facultyId: number | null;
  public studyLevelId: number | null;
  public programId: number | null;
  public forAdmin: boolean;
  public forLecturer: boolean;
  public forStudent: boolean;
  public skip: number;
  public take: number;
}

export class  InformationBoardData {
  public id: number | null;
  public infoTypeId: number;
  public statusId: number;
  public publishDateTime: Date;
  public infoTypeCaption: string | null;
  public statusCaption: string | null;
  public informationBoardDataHeader: Array<InformationBoardDataHeader> | null;
  public informationBoardDataFor: Array<InformationBoardDataFor> | null;
}
export class  InformationBoardDataFor {
  public id: number | null;
  public facultyId: number | null;
  public studyLevelId: number | null;
  public programId: number | null;
  public facultyCaption: string | null;
  public StudyLevelCaption: string | null;
  public ProgramCaption: string | null;
}

export class  InformationBoardDataHeader {
  public id: number | null;
  public headerText: string | null;
  public fullText: string | null;
  public shortText: string | null;
  public linkUrl: string | null;
  public languageId: number;
  public languageCaption: string | null;
  public informationBoardDataFiles: Array<InformationBoardDataFile> | null;
}

export class  InformationBoardDataFile {
  public id: number | null;
  public fileStreamId: string;
  public fileName: string | null;
  public isAvatar: boolean;
}

import { Component, Inject, Injectable, EventEmitter, ViewChild, ElementRef, Renderer2, Input, Output, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatSelectTrigger } from '@angular/material/select';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { merge, Observable, of as observableOf, BehaviorSubject, Scheduler, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/collections';
import { StudentProfileData } from '../../Models/StudentProfile';
import { CardService } from '../Service/CardService';
import { StudentsListData } from '../../Models/StudentsList';
import { LanguageService } from '../../Service/LanguageService';
import { Caption, PortalLanguageCaption } from '../../LanguageModel/Caption';
import { StudentListOnLecture, StudentEvaluationGenerator, EvaluationHeader, SaveEvaluationMarks, FilterGroups } from '../../Models/Graids';
import { StudyGroupFilter } from '../../Models/filters';
import { EditRowCell, EditCell } from '../../Models/FIltering';
import { GradeFunctions } from '../../Functions/graidFunctions';
import { GraideService } from '../Service/GraideService';
import { SharedService } from '../Service/SharedService';
import { LecturerReadCourses } from '../../Models/Evaluations';
import { ObjectForFillSelect } from '../../Models/ObjectForFillSelect';
import { StudyMaterialService } from '../Service/StudyMaterialService';
import { StudyMaterial } from '../../Models/StudyMaterial';
import { FileUploadReturn } from '../../Models/file';
import { SyllabusData } from '../../Models/SyllabusRetData';

@Component({
  selector: 'StudyMaterial',
  templateUrl: 'StudyMaterial.html',
  styleUrls: ['StudyMaterial.css']
})

export class StudyMaterialComponent {

  _filter: FilterGroups
  materialList: Array<StudyMaterial> = new Array<StudyMaterial>();
  weekList: any;
  syllabusData: SyllabusData;
  editCell: EditCell = { rowId: 0, cellValue: "" };
  public captions: Caption;
  constructor(public dialog: MatDialog,
    private SharedService: SharedService,
    private GraideService: GraideService,
    private studyMaterialService: StudyMaterialService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router, private cdr: ChangeDetectorRef) {
    //this.SharedService.GetWeeksForSyllabus().then(e => { this.weekList = e; });
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];
  }
  ngAfterViewInit() {

  }
  courseId: number;
  Filter(filter: FilterGroups) {
    this._filter = filter;
    if (this.courseId != filter.courseId) {
      this.GraideService.GetSyllabusData(filter.courseId).then(e => {
        this.syllabusData = e;
        
        this.SharedService.GetWeeksForSyllabus(this.syllabusData.syllabus.duration).then(e => { this.weekList = e; });
        
      });
      this.courseId == filter.courseId;
    }


      this.studyMaterialService.GetStudyMaterialByGroups(filter.groupId).then(e => {
        this.materialList = e;
       
      });
  }
  FileUploaded(file: FileUploadReturn) {
    this.materialList.push({
      fileStreamId: file.fileId, fileName: file.fileName, id: null, forDayDate: null, description: "",
      groupId: this._filter.groupId, weekId: null, weekName: null
    });
    this.SaveMaterila();
  }

  SaveMaterila(): void {
    this.studyMaterialService.UpdateSaveMaterialByGroups({ groupId: this._filter.groupId, studyMaterialList: this.materialList })
      .then(e => {
        if (e.seccuss) {
          alert("მონაცემები წარმატებით განახლად!");
          this.studyMaterialService.GetStudyMaterialByGroups(this._filter.groupId).then(e => {
            this.materialList = e;
          });
        }
        else {
          alert(e.resultText);
        }
        });
  }

  FileDelete(row: any) {
    var index = this.materialList.indexOf(row);
    this.materialList.splice(index, 1);
  }
  DownloadDocument(fileId: string) {
    this.SharedService.downloadFile(fileId);
  }
  toggleCaption(id: EditCell, current: any) {
    this.editCell = id;
  }
}


<div class="_cs_flex _cs_flex-a--end _cs_flex _cs_flex-a--end _cs_m-down--lg">
  <span class="_cs_label _cs_label-lg _cs_label-font-setting-case-on _cs_label-500 _cs_color-primary-2">{{captions.readMore}}</span>
  <button class="_cs_m-left--auto _cs_icon _cs_icon-lg _cs_border-radius--full _cs_shadow-primary-0-0--3 _cs_color-bg--primary-5" [mat-dialog-close]="true">
    <span class="_cs_icon _cs_icon-xs _cs_icon-cross _cs_color-primary-2"></span>
  </button>
</div>
<mat-dialog-content>
  <div *ngIf="informationHeader" class="_cs_row">
    <div class="_cs_col-xs-6">
      <div class="_cs_flex _cs_size-w-percent--100">
        <ng-container class="_cs_size-w-percent--100" *ngFor="let f of informationHeader.informationBoardDataFiles">
          <ProfileImage class="_cs_size-w-percent--100 _cs_child-img-w--full _cs_overflow-hidden _cs_border-radius--sm" *ngIf="f.isAvatar" [fileId]="BehavRet(f.fileStreamId)">

          </ProfileImage>
        </ng-container>
      </div>
    </div>
<div class="_cs_col-xs-6">
  <div class="_cs_p-left--lg">
          <div>
            <p class="_cs_label _cs_label-lg _cs_label-t-u _cs_label-500 _cs_label-font-setting-case-on _cs_color-primary-2">{{informationHeader.headerText}}</p>
            <p class="_cs_label _cs_label-md _cs_label-500 _cs_label-font-setting-case-on _cs_color-primary-2">{{data.publishDateTime}}</p>
          </div>
          <div class="_cs_p-down--lg">
            <div class="_cs_label _cs_label-md _cs_label-500 _cs_label-font-setting-case-on _cs_color-primary-2" [innerHTML]="informationHeader.fullText"></div>
          </div>
          <div >
            <span class="_cs_label _cs_label-lg _cs_label-t-u _cs_label-500 _cs_label-font-setting-case-on _cs_color-primary-2"
                  *ngIf="informationHeader.informationBoardDataFiles!=null&&informationHeader.informationBoardDataFiles.length>0">
              {{captions.documents}}
            </span>
            <div *ngFor="let s of informationHeader.informationBoardDataFiles;">
              <ng-container *ngIf="!s.isAvatar">
                <button class="link" (click)="DownloadDocument(s.fileStreamId)">{{s.fileName}}</button>
              </ng-container>
            </div>
          </div>
  </div>
</div>
</div>
   
</mat-dialog-content>
<mat-dialog-actions align="end">    
    <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_m-left--md" [mat-dialog-close]="true">
      <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.close}}</span>
    </button>
</mat-dialog-actions>

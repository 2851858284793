import { Component, Inject, Injectable, EventEmitter, ViewChild, ElementRef, Renderer2, Input, Output, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatSelectTrigger } from '@angular/material/select';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { merge, Observable, of as observableOf, BehaviorSubject, Scheduler, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/collections';
import { ProfileService } from '../Service/ProfileService';
import { EditCell } from '../../Models/FIltering';
import { FilterSubjectSylabusCourseEduPrograms, FilterPersone, UIfilter } from '../../Models/filters';
import { Language } from '../../Models/language';
import { StudAnketaData, StudentStudyPrograms, StudName } from '../../Models/StudAnketa';
import { PersonEditionAnketData, PerConferenceP, PerEducataionP, PerPracticalExperienceP, PerPublicationsP, PerProfesionGrowthP, PerProjectsP, PersonContactData, PersonDocument, ContactTypeGroupCategory, PersonData, ProfilePermission } from '../../Models/Profile';
import { FileUploadReturn } from '../../Models/file';
import { ReturnResultValue } from '../../Models/ResultReturnParameters';
import { SharedService } from '../Service/SharedService';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../Identity/_services';
import { PortalLanguageCaption, Caption } from '../../LanguageModel/Caption';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'profile',
  templateUrl: 'Profile.html',
  styleUrls: ['Profile.css']
})



export class Profile implements OnInit {
  userAnketaData: PersonData;
  contactTypeGroupCategorie: Array<ContactTypeGroupCategory>
  nameRowindex: number;
  nameRowId: number;
  languageList: any;
  public directionList: any;
  public facultyList: any;
  public studyLevelList: any;
  public personalCategoryList: any;
  public workerStatus: any;
  languageListFiltered: any;
  editCell: EditCell = { rowId: 0, cellValue: "" };
  userRules: ProfilePermission;
  fileForList: any;
  selectedFileFor: any;
  sexList: any;
  editionDataTypeList: any;
  editionDataElementList: any;
  selectedEnterType: any;
  evaluationTypes: any;

  profileItemNumber: number = 1;
  activeNode: any;
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  private _transformer = (node: ProfileItem, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id,
      icon: node.icon,
      haveChildren: node.children != null && node.children.length > 0 ? true : false
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  languageId: number = 0;
  public captions: Caption;
  constructor(
    private activatedRoute: ActivatedRoute,
    //private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,
    private ProfileService: ProfileService,
    private route: ActivatedRoute,
    private SharedService: SharedService,
    private location: Location,
    private router: Router,
    private _sanitizer: DomSanitizer,
    private _snackBar: MatSnackBar
  ) {
    

  }
  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }
  ngOnInit() {
    this.SharedService.GetFakultyList().then(e => { this.facultyList = e; this.facultyList.push({ id: null, caption: "ყველა" }); });
    this.SharedService.GetStudyLevelList().then(e => { this.studyLevelList = e; this.studyLevelList.push({ id: null, caption: "ყველა" }); });
    //this.SharedService.GetWorkerStatus().then(e => { this.workerStatus = e; });
    this.SharedService.GetLanguage().then(e => { this.languageList = e });
    //this.UserService.UserRole(this.data.PersoneId).then(e => { this.userRules = e as ProfilePermission});

    this.ProfileService.UserAnkData().then(e => { this.userAnketaData = e as PersonData; this.GetPhoto(); });
   
    this.SharedService.GetFileFor().then(e => { this.fileForList = e });
    this.SharedService.GetContactCategories().then(e => { this.contactTypeGroupCategorie = e as Array<ContactTypeGroupCategory> });
   /* this.SharedService.GetSex().then(e => {
      this.sexList = e;
    });*/
    this.SharedService.GetEditionDataTypes().then(e => { this.editionDataTypeList = e });


    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];
    this.languageId = parseInt(localStorage.getItem("language"));

    let pIt: Array<ProfileItem> = new Array<ProfileItem>(6);
    pIt[0] = new ProfileItem();
    pIt[1] = new ProfileItem();
    pIt[2] = new ProfileItem();
    pIt[3] = new ProfileItem();
    pIt[4] = new ProfileItem();

    pIt[0].id = 1;
    pIt[0].name = this.captions.anketa;
    pIt[0].icon = "_cs_icon-menu-card";
    pIt[1].id = 2;
    pIt[1].name = this.captions.contactInformation;
    pIt[1].icon = "_cs_icon-menu-contact";
    pIt[2].id = 3;
    pIt[2].name = this.captions.educationActivities;
    pIt[2].icon = "_cs_icon-menu-education-activities";
    pIt[2].children = new Array<ProfileItem>(7);
    pIt[2].children[0] = new ProfileItem();
    pIt[2].children[1] = new ProfileItem();
    pIt[2].children[2] = new ProfileItem();
    pIt[2].children[3] = new ProfileItem();
    pIt[2].children[4] = new ProfileItem();
    pIt[2].children[5] = new ProfileItem();
    pIt[2].children[6] = new ProfileItem();

    pIt[2].children[0].id = 31;
    pIt[2].children[0].name = this.captions.education;
    pIt[2].children[0].icon = "room";
    pIt[2].children[1].id = 32;
    pIt[2].children[1].name = this.captions.conferences;
    pIt[2].children[1].icon = "room";
    pIt[2].children[2].id = 33;
    pIt[2].children[2].name = this.captions.publications;
    pIt[2].children[2].icon = "room";
    pIt[2].children[3].id = 34;
    pIt[2].children[3].name = this.captions.professionalDevelopment;
    pIt[2].children[3].icon = "room";
    pIt[2].children[4].id = 35;
    pIt[2].children[4].name = this.captions.practicalExperience;
    pIt[2].children[4].icon = "room";
    pIt[2].children[5].id = 36;
    pIt[2].children[5].name = this.captions.projects;
    pIt[2].children[5].icon = "room";
    pIt[2].children[6].id = 37;
    pIt[2].children[6].name = this.captions.transferSkills; 
    pIt[2].children[6].icon = "room";
    pIt[3].id = 4;
    pIt[3].name = this.captions.orders;
    pIt[3].icon = "_cs_icon-menu-order";
    pIt[4].id = 5;
    pIt[4].name = this.captions.documents;
    pIt[4].icon = "_cs_icon-menu-order";
    this.dataSource.data = pIt;
  }


  saveProfile(): void {

    this.ProfileService.SaveUserDataByLecturer(this.userAnketaData).then(e => {
      if ((e as ReturnResultValue).seccuss) {

        this.ProfileService.UserAnkData().then(e => { this.userAnketaData = e as PersonData; this.GetPhoto(); });
        this._snackBar.open("ანკეტური მონაცემები", "მონაცემები განახლდა!", {
          duration: 2000,
        });
      }
      else {
        this._snackBar.open("ანკეტური მონაცემები", "მოხდა შეცდომა!", {
          duration: 2000,
        });
      }
     

    });

  }

  

  setClickedNameRow(i: any, id: any): void {
    this.nameRowindex = i;
    this.nameRowId = id;
  }



  FilterLanguages(language: Language[], item: any, current: any): void {
    let ar = new Array<Language>();
    for (let i = 0; i < language.length; i++) {
      let aris = true;
      for (let it = 0; it < item.length; it++) {
        if (item[it].languageId == language[i].id) {
          aris = false;
        }
      }

      if (aris || language[i].id == current.languageId) {
        ar.push(language[i]);
      }

    }
    this.languageListFiltered = ar;
  }

  toggleCaption(id: EditCell, current: any) {
    this.editCell = id;

    if (id.cellValue == "studentNameLaguage") {

      this.FilterLanguages(this.languageList, this.userAnketaData.nameList, current);
    } else
      if (id.cellValue == 'directionName') {
        this.FilterDirections(this.userAnketaData.profilePermission.dataPermission, current)
      }

  }

  RemoveCRaw(dataListName: string, row: any): void {
    if (confirm("გინდათ წაშალოთ ჩანაწერი!")) {
      if (dataListName == "userRulesdatas") {
        var index = this.userRules.dataPermission.indexOf(row);
        this.userAnketaData.profilePermission.dataPermission.splice(index, 1);

      }
      else if (dataListName == "studentName") {
        var index1 = this.userAnketaData.nameList.indexOf(row);
        this.userAnketaData.nameList.splice(index1, 1);
      }
      else if (dataListName == 'removePosition') {
        var index2 = this.userAnketaData.workers.indexOf(row);
        this.userAnketaData.workers.splice(index2, 1);
      } else if (dataListName == "contact") {
        var index = this.userAnketaData.contactData.indexOf(row);
        this.userAnketaData.contactData.splice(index, 1);
      } else if (dataListName == "documentFile") {
        var index = this.userAnketaData.files.indexOf(row);
        this.userAnketaData.files.splice(index, 1);
        this.saveProfile();
      } else if (dataListName == "anket2") {
        var index = this.userAnketaData.editionAnketData.indexOf(row);
        this.userAnketaData.editionAnketData.splice(index, 1);
      } else if (dataListName == 'perEducataion') {
        var index = this.userAnketaData.perEducataion.indexOf(row);
        this.userAnketaData.perEducataion.splice(index, 1);
      } else if (dataListName == 'perConference') {
        var index = this.userAnketaData.perConference.indexOf(row);
        this.userAnketaData.perConference.splice(index, 1);
      }
      else if (dataListName == 'perPracticalExperience') {
        var index = this.userAnketaData.perPracticalExperience.indexOf(row);
        this.userAnketaData.perPracticalExperience.splice(index, 1);
      }
      else if (dataListName == 'perPublications') {
        var index = this.userAnketaData.perPublications.indexOf(row);
        this.userAnketaData.perPublications.splice(index, 1);
      }
      else if (dataListName == 'perProfesionGrowth') {
        var index = this.userAnketaData.perProfesionGrowth.indexOf(row);
        this.userAnketaData.perProfesionGrowth.splice(index, 1);
      }
      else if (dataListName == 'perProjects') {
        var index = this.userAnketaData.perProjects.indexOf(row);
        this.userAnketaData.perProjects.splice(index, 1);
      }
    }

  }

  addCRow(dataListName: string): void {
    if (dataListName == "accessOnData") {
      this.userAnketaData.profilePermission.dataPermission.push({
        id: null,
        facultyId: null,
        studyLevelId: null,
        directionId: null,
        readData: null,
        writeData: null,
        facultyHeader: null,
        studyLevelHeader: null,
        programsDirectionsHeader: null
      });

    } else if (dataListName == "studentName") {
      if (this.userAnketaData.nameList.length < this.languageList.length) {

        this.userAnketaData.nameList.push({ languageId: null, id: null, sName: null, fName: null, language: null, pName: null });
      }
    }
    else if (dataListName == "contact") {
      this.userAnketaData.contactData.push({ languageId: null, language: "", categoryId: null, categoryName: "", description: "", id: null });
    } else if (dataListName == "perEducataion") {
      let v = new PerEducataionP();
      this.userAnketaData.perEducataion.push(v);
    } else if (dataListName == 'perConference') {
      let v = new PerConferenceP();
      this.userAnketaData.perConference.push(v);
    } else if (dataListName == 'perPracticalExperience') {
      let v = new PerPracticalExperienceP();
      this.userAnketaData.perPracticalExperience.push(v);
    }
    else if (dataListName == 'perPublications') {
      let v = new PerPublicationsP();
      this.userAnketaData.perPublications.push(v);
    }
    else if (dataListName == 'perProfesionGrowth') {
      let v = new PerProfesionGrowthP();
      this.userAnketaData.perProfesionGrowth.push(v);
    }
    else if (dataListName == 'perProjects') {
      let v = new PerProjectsP();
      this.userAnketaData.perProjects.push(v);
    }

  }
  LoadEditionDataElementList(id: number) {
    this.SharedService.GetEditionDataList(id).then(e => { this.editionDataElementList = e });
    this.SharedService.GetEditionAnketDataEvaluationTypes(id).then(e => { this.evaluationTypes = e });
  }
  addAnk2Data(enableEvalution: boolean) {

    let pr = new PersonEditionAnketData();
    pr.evaluateEnabled = enableEvalution;
    this.userAnketaData.editionAnketData.push(pr);

  }


  FilterDirections(item: any, current: any): void {
    this.directionList = new Array<Direction>();
    if (current.facultyId != null) {
      this.SharedService.GetDirectionList(current.facultyId).then(e => {
        let ar = new Array<Direction>();
        ar.push({ id: null, caption: "ყველა" });
        for (let i = 0; i < e.length; i++) {
          let aris = true;
          for (let it = 0; it < item.length; it++) {
            if (item[it].directionId == e[i].id) {
              aris = false;
            }
          }

          if (aris || e[i].id == current.directionId) {
            ar.push({ id: e[i].id, caption: e[i].caption });
          }
          //}
          this.directionList = ar;
        }
      });
    }
  }

  FileUploaded(file: FileUploadReturn) {
    this.userAnketaData.files.push({
      fileStream_id: file.fileId, id: null,
      fileForId: this.selectedFileFor.id, fileFor: this.selectedFileFor.caption, personalId: null, personId: null, studentId: null, fileName: file.fileName, documentDescription:""
    });
    this.saveProfile();
  }

  FileUploaded2(file: FileUploadReturn) {
    this.selectedPhoto = null;
    this.userAnketaData.files.push({
      fileStream_id: file.fileId, id: null,
      fileForId: 1, fileFor: "ProfileImage", personalId: null, personId: null, studentId: null, fileName: file.fileName, documentDescription:""
    });
    this.saveProfile();
    
  }


  BehavRet(selectedPhoto: string): BehaviorSubject<string> {
    return new BehaviorSubject(selectedPhoto);
  }
  selectedPhoto: string="0";
  GetPhoto(): void {
    for (let i = 0; i < this.userAnketaData.files.length; i++) {
      if (this.userAnketaData.files[i].fileForId == 1) {
        //alert(this.userAnketaData.files[i].fileStream_id);
        this.selectedPhoto = this.userAnketaData.files[i].fileStream_id;
      }
    }
  }
  FilterItem(node: ExampleFlatNode) {
    console.log(node);
    if (!node.haveChildren) {
      this.profileItemNumber = node.id;
    }
  }

  DownloadDocument(fileId: string) {
    this.SharedService.downloadFile(fileId);
  }
}
export interface Direction {
  id: number;
  caption: string;
}

export interface Faculty {
  id: number;
  caption: string;
}

export class ProfileItem {
  public id: number;
  public name: string;
  public icon: string;
  public children?: ProfileItem[];
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  id: number;
  icon: string;
  haveChildren: boolean
}

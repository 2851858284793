<div class=" _cs_position-relative _cs_z-2 _cs_size-h-min-vh--100 _cs_flex _cs_flex-a--center _cs_flex-j--center">
  <div class="_cs_flex _cs_overflow-h _cs_border-radius--sm _cs_color-bg--primary-5 _cs_m-down--xxxl _cs_mobile-flex-d-r _cs_login-wrap">
    <div class="_cs_flex _cs_size-w-percent--100 _cs_p-lg _cs_flex-d--column _cs_mobile-m-down--auto">
      <div style="z-index:10; text-align:right">
        <button [ngClass]="{'selectetButton':geoActive==1,'unSelectetButton':geoActive!=1}" (click)="selectLanguage(1)">GE</button>
        <button [ngClass]="{'selectetButton':geoActive!=1,'unSelectetButton':geoActive==1}" (click)="selectLanguage(2)">EN</button>
      </div>
      <span class="_cs_label _cs_label-sm _cs_color-primary-2 _cs_flex _cs_flex-j--center">
        {{captions.pleaseLogin}}
      </span>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="_cs_p-lg" autocomplete="off">
        <div class="_cs_flex _cs_size-w-percent--100 _cs_flex-d--column">
          <div class="_cs_p-sm _cs_size-w-min-px--330">
            <span class="_cs_label _cs_label-sm _cs_color-primary-2">{{captions.userName}}</span>
          </div>
          <input id="email" formControlName="username" name="Full Name" placeholder={{captions.userName}}
                 class="_cs_input _cs_input-clear _cs_size-w-percent--100 _cs_border-radius--xxl custom-border-gray"
                 type="text" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
        </div>
        <div class="_cs_p-sm _cs_size-w-min-px--330">
          <span class="_cs_label _cs_label-sm _cs_color-primary-2">{{captions.password}}</span>
        </div>
        <div class="_cs_flex _cs_size-w-percent--100 _cs_flex-d--column">
          <input type="password" formControlName="password"
                 class="_cs_input _cs_input-clear _cs_size-w-percent--100 _cs_border-radius--xxl custom-border-gray"
                 id="pass" placeholder={{captions.password}} [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
        </div>
        <div class="form-group">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="updatecheck1">
            <label class="form-check-label" for="updatecheck1">
              <small class="_cs_label _cs_label-sm _cs_color-primary-2">{{captions.rememberMe}} </small>

            </label>
          </div>
        </div>

        <div class="form-row _cs_m-down--md">
          <button [disabled]="loading" class="_cs_button _cs_color-bg-gradient--primary-4 _cs_size-w-percent--100">
            <span class="_cs_label _cs_color-primary-5">{{captions.login}}</span>
          </button>
          <div class="_cs_col-xs-6 _cs_m-left--auto _cs_m-right--auto _cs_flex _cs_flex-j--center _cs_p-lg">
            <div *ngIf="loading">
              <div class="wrapp">
                <div class="chevron"></div>
                <div class="chevron"></div>
                <div class="chevron"></div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="form-row _cs_m-down--sm">
        <button (click)="RecoverPassword()" class="link">
          {{captions.resetPassword}}
        </button>
      </div>
    </div>
    <div
      class="_cs_login-logo _cs_flex _cs_flex-a--center _cs_flex-j--center _cs_p-xxl _cs_color-bg--primary-6 _cs_border-radius-right-top--sm _cs_border-radius-right-down--sm">
      <a href="#"><img src="./logo-white.png" class="_cs_size-w-px--150" alt=""></a>
    </div>
  </div>
</div>

import { Component, Inject, EventEmitter, Injectable, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { UserService } from '../_services/user.service';
import { ChangePasswordData, ResetPasswordData } from '../../Models/Profile';
import { Caption, PortalLanguageCaption } from '../../LanguageModel/Caption';

@Component({
  selector: 'RecoverPassword',
  templateUrl: './RecoverPassword.html',
  styleUrls: ['./RecoverPassword.css']
})
/** ChangePassword component*/
export class RecoverPassword {
  /** ChangePassword ctor */
  public currentPassword: string;
  public newPassword: string;
  public confirmPassword: string;
  public recoverPassword: ResetPasswordData = new ResetPasswordData();
  public captions: Caption;

  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<RecoverPassword>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private activatedRoute: ActivatedRoute,
    //private SharedService: SharedService,
    private route: ActivatedRoute,
    private location: Location,
    private userService: UserService,
    private router: Router, private fb: FormBuilder) {

    if (localStorage.getItem("languageMainPage") != null) {
      this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("languageMainPage")) - 1];
    }
    else {
      this.captions = PortalLanguageCaption[0];
    }
  }
  shorCodeFiled: boolean= false;
  ResetCodeToEmail(): void {
;
      this.userService.ResetCodeToEmail(this.recoverPassword).then(e => {
        if (e.seccuss) {
          this.shorCodeFiled = true;
        }
        else {
          alert("მოხდა შეცდომა!");
        }
      });
  }

  ChangePassword(): void {

      this.userService.resetPassword(this.recoverPassword).then(e => {
        if (e.seccuss) {
          alert("წარმატებით შეიცვალა!");
          this.dialogRef.close();
        }
        else {
          alert("მოხდა შეცდომა!");
        }
      });
      
    
  }
}

import { Component, Inject, Injectable, EventEmitter, ViewChild, ElementRef, Renderer2, Input, Output, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatSelectTrigger } from '@angular/material/select';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { merge, Observable, of as observableOf, BehaviorSubject, Scheduler, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/collections';
import { StudentProfileData } from '../../Models/StudentProfile';
import { CardService } from '../Service/CardService';
import { StudentsListData } from '../../Models/StudentsList';
import { LanguageService } from '../../Service/LanguageService';
import { Caption, PortalLanguageCaption } from '../../LanguageModel/Caption';
import { LecturerLoad } from '../../Models/LecturerLoad';

@Component({
  selector: 'card',
  templateUrl: 'Card.html',
  styleUrls: ['Card.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})



export class Card implements OnInit {
  public lecturerLoad: Array<LecturerLoad>;

  public captions: Caption;
  columsToDisplay = ['groupName', 'syllabusCaption',
    'studylevelName', 'facultyName', 'lectureCaption',
    'year', 'semester', 'duration', 'houars', 'shedulerTypeCaption', 'credit', 'studCount','subjectTypeCaption']; 
  constructor(
    private activatedRoute: ActivatedRoute, private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    public CardService: CardService,
    public LanguageService: LanguageService,
    private http: HttpClient) {
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language"))-1];
    
    

  }
  ngOnInit() {
    
    this.CardService.LecturerLoad().then(e => {
      this.lecturerLoad = e as Array<LecturerLoad>;
      });
  }



}


export class Group {
  level = 0;
  parent: Group;
  expanded = true;
  totalCounts = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}

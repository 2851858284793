export class GradeFunctions {
  public static CalculatateMarkFromFormule(SumMark: number, MaxCompCountOnWeeks: number, MaxRanking: number, CalculationTypeID: number, MaxMark: number):number {
    if (CalculationTypeID == 1) {
      return SumMark;
    }
    else if (CalculationTypeID == 2) {
      return SumMark / MaxCompCountOnWeeks;
    }
    else if (CalculationTypeID == 3) {
      return MaxMark * (SumMark / MaxCompCountOnWeeks);
    }
    else if (CalculationTypeID == 4) {
      return (MaxMark / MaxCompCountOnWeeks) * SumMark / MaxRanking;
    }
    return 0;
  }
}

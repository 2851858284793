import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models';
import { CaptionGE, CaptionENG } from '../../LanguageModel/Caption';
import { CookieService } from 'ngx-cookie-service';
import { ShearedDataService } from '../../Lecturer/Service/ShearedData';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
  private baseUrl = window.location.origin + '/api/Auth';

  constructor(private http: HttpClient, private cookieService: CookieService, private shearedDataService: ShearedDataService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

  login(username: string, password: string) {
 
      return this.http.post<any>(`${this.baseUrl}/Login`, { Username: username, Password: password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                  localStorage.setItem('currentUser', JSON.stringify(user));
                  localStorage.setItem('language', user.lecturer.interfaceLanguage);
                  this.currentUserSubject.next(user);
                  this.shearedDataService.changeMode();
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
      localStorage.clear();
      this.cookieService.deleteAll();
      this.currentUserSubject.next(null);
      this.shearedDataService.changeMode();
      return this.http.post<any>(`${this.baseUrl}/Logout`, null).toPromise().then(response => response)
        .catch(this.handleError);
    }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}

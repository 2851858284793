<div class="_cs_size-w-percent--100 _cs_shadow-sm _cs_border-radius--sm _cs_color-bg--primary-5 _cs_p-md _cs_m-down--lg _cs_p-down--none">
  <div class="_cs_flex _cs_flex-a--center _cs_flex-wrap">

    <div class="_cs_col-xs-12 _cs_col-sm-6 _cs_col-md-3 _cs_p-left--sm _cs_p-right--sm">
      <mat-form-field class="_cs_p-md _cs_shadow-primary-0-0--1 _cs_border-radius--xxl _cs_size-w-percent--100 _cs_color-bg--primary-5 _cs_m-down--md _cs_p-down--none">
        <mat-select placeholder={{captions.faculty}} [(ngModel)]="facultySelected" (selectionChange)="fakultyCahnged($event)">
          <mat-option *ngFor="let ls of fakultyList" [value]="ls.id">{{ls.caption}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="_cs_col-xs-12 _cs_col-sm-6 _cs_col-md-3 _cs_p-left--sm _cs_p-right--sm">
      <mat-form-field class="_cs_p-md _cs_shadow-primary-0-0--1 _cs_border-radius--xxl _cs_size-w-percent--100 _cs_color-bg--primary-5 _cs_m-down--md _cs_p-down--none">
        <mat-select id="studyLevel" placeholder={{captions.studyLevel}} [(ngModel)]="selectedStudyLevel"
                    (selectionChange)="studyLevelCahnged($event)">
          <mat-option *ngFor="let ls of studyLevelList" [value]="ls.id">{{ls.caption}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="_cs_col-xs-12 _cs_col-sm-6 _cs_col-md-3 _cs_p-left--sm _cs_p-right--sm">
      <mat-form-field class="_cs_p-md _cs_shadow-primary-0-0--1 _cs_border-radius--xxl _cs_size-w-percent--100 _cs_color-bg--primary-5 _cs_m-down--md _cs_p-down--none">
        <mat-select id="studyYear" placeholder={{captions.yearName}} [(ngModel)]="selectedYear"
                    (selectionChange)="YearChange($event)">
          <mat-option *ngFor="let ls of studyYear" [value]="ls.caption">{{ls.caption}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="_cs_col-xs-12 _cs_col-sm-6 _cs_col-md-3 _cs_p-left--sm _cs_p-right--sm">
      <mat-form-field class="_cs_p-md _cs_shadow-primary-0-0--1 _cs_border-radius--xxl _cs_size-w-percent--100 _cs_color-bg--primary-5 _cs_m-down--md _cs_p-down--none">
        <mat-select id="studySemester" placeholder={{captions.semName}} [(ngModel)]="studySemesterSelected"
                    (selectionChange)="SemesterChange($event)">
          <mat-option *ngFor="let ls of studySemester" [value]="ls.caption">{{ls.caption}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="_cs_col-xs-12 _cs_col-sm-6 _cs_col-md-3 _cs_p-left--sm _cs_p-right--sm">
      <mat-form-field class="_cs_p-md _cs_shadow-primary-0-0--1 _cs_border-radius--xxl _cs_size-w-percent--100 _cs_color-bg--primary-5 _cs_m-down--md _cs_p-down--none">
        <mat-select id="studyCourse" placeholder={{captions.subjectName}} [(ngModel)]="selectedstudyCourse"
                    (selectionChange)="CourseChange($event)">
          <mat-option *ngFor="let ls of studyCourse" [value]="ls.id">{{ls.caption}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="_cs_col-xs-12 _cs_col-sm-6 _cs_col-md-3 _cs_p-left--sm _cs_p-right--sm">
      <mat-form-field class="_cs_p-md _cs_shadow-primary-0-0--1 _cs_border-radius--xxl _cs_size-w-percent--100 _cs_color-bg--primary-5 _cs_m-down--md _cs_p-down--none">
        <mat-select placeholder={{captions.lectureType}} [(ngModel)]="lecutreTypeselected"
                    (selectionChange)="LectureTypeChange($event)">
          <mat-option [value]="-1">{{captions.Exam}}</mat-option>
          <mat-option *ngFor="let ls of lecutreTypeList" [value]="ls.id">{{ls.caption}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="_cs_col-xs-12 _cs_col-sm-6 _cs_col-md-3 _cs_p-left--sm _cs_p-right--sm">
      <mat-form-field class="_cs_p-md _cs_shadow-primary-0-0--1 _cs_border-radius--xxl _cs_size-w-percent--100 _cs_color-bg--primary-5 _cs_m-down--md _cs_p-down--none">
        <mat-select placeholder={{captions.group}} [(ngModel)]="groupSelected">
          <mat-option *ngFor="let ls of groupList" [value]="ls">{{ls.groupName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="_cs_col-xs-12 _cs_col-sm-6 _cs_col-md-3 _cs_p-left--sm _cs_p-right--sm">
      <button (click)="ShowStudentList()" type="button"
              class="_cs_button _cs_button-sm  _cs_button-border--positive _cs_m-down--md _cs_size-w-min-px--150">
        <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.search}}</span>
      </button>
    </div>
  </div>

</div>

import { Component, Inject, Injectable, EventEmitter, ViewChild, ElementRef, Renderer2, Input, Output, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatSelectTrigger } from '@angular/material/select';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { merge, Observable, of as observableOf, BehaviorSubject, Scheduler, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/collections';
import { StudentProfileData } from '../../Models/StudentProfile';
import { CardService } from '../Service/CardService';
import { StudentsListData } from '../../Models/StudentsList';
import { LanguageService } from '../../Service/LanguageService';
import { Caption, PortalLanguageCaption } from '../../LanguageModel/Caption';
import { StudentListOnLecture, StudentEvaluationGenerator, EvaluationHeader, SaveEvaluationMarks, FilterGroups } from '../../Models/Graids';
import { StudyGroupFilter } from '../../Models/filters';
import { EditRowCell, EditCell } from '../../Models/FIltering';
import { GradeFunctions } from '../../Functions/graidFunctions';
import { GraideService } from '../Service/GraideService';
import { SharedService } from '../Service/SharedService';
import { LecturerReadCourses } from '../../Models/Evaluations';
import { ObjectForFillSelect } from '../../Models/ObjectForFillSelect';
import * as XLSX from 'xlsx';
import { SyllabusComponentOnWeek, SyllabusData } from '../../Models/SyllabusRetData';

@Component({
  selector: 'MarkJounal',
  templateUrl: 'MarkJounal.html',
  styleUrls: ['MarkJounal.css']//,
 // changeDetection: ChangeDetectionStrategy.OnPush
})



export class MarkJounal  {
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  studentEvaluationGenerator: StudentEvaluationGenerator;
  editCell: EditRowCell = { rowId: 0, cellId: 0, cellValue: "" };
  changedMarks = new Array<SaveEvaluationMarks>();
  syllabusComponents = new Array<EvaluationHeader>();
  //theHtmlString: string;
  //theHtmlString: string;

 
  pageIndex: number = 0;
  pageSize: number = 20;
  lowValue: number = 0;
  highValue: number = 20;

  getPaginatorData(event) {
   
    if (event.pageIndex === this.pageIndex + 1) {
      this.lowValue = this.lowValue + this.pageSize;
      this.highValue = this.highValue + this.pageSize;
    }
    else if (event.pageIndex === this.pageIndex - 1) {
      this.lowValue = this.lowValue - this.pageSize;
      this.highValue = this.highValue - this.pageSize;
    }
    this.pageIndex = event.pageIndex;
  }
  //@ViewChild(MdbTableDirective, { static:false }) mdbTable: MdbTableDirective;
  //@ViewChild(MdbTablePaginationComponent, { static: false }) mdbTablePagination: MdbTablePaginationComponent;
  //@ViewChild('row', { static: false }) row: ElementRef;
  public captions: Caption;
  constructor(public dialog: MatDialog,
    private SharedService: SharedService,
    private GraideService: GraideService,

    private route: ActivatedRoute,
    private location: Location,
    private router: Router, private cdr: ChangeDetectorRef) {
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];
  }
  ngAfterViewInit() {

  }
  //refresh() {
  //  this.cdr.detectChanges();
  //}

  WeekIdentyCount(weekId: number): number {
    let i = 0;
    for (let k = 0; k < this.studentEvaluationGenerator.evaluationHeader.length; k++) {
      if (this.studentEvaluationGenerator.evaluationHeader[k].weekId == weekId) {
        i++;
      }
    }
    return i;
  }

  ComponentIdentyCount(componentId: number, weekId: number): number {
    let i = 0;
    for (let k = 0; k < this.studentEvaluationGenerator.evaluationHeader.length; k++) {
      if (this.studentEvaluationGenerator.evaluationHeader[k].componentOnSyllabusId == componentId &&
        this.studentEvaluationGenerator.evaluationHeader[k].weekId == weekId) {
        i++;
      }
    }
    return i;
  }
  weekDayIdIdentyCount(weekDayId: number, weekId: number, componentId: number): number {
    let i = 0;
    for (let k = 0; k < this.studentEvaluationGenerator.evaluationHeader.length; k++) {
      if (this.studentEvaluationGenerator.evaluationHeader[k].weekDayId == weekDayId && this.studentEvaluationGenerator.evaluationHeader[k].weekId == weekId && this.studentEvaluationGenerator.evaluationHeader[k].componentOnSyllabusId == componentId) {
        i++;
      }
    }
    return i;
  }

  counter(num: number): Array<number> {
    let v = new Array<number>();
    for (let i = 1; i <= num; i++) {
      v.push(i);
    }

    return v;
  }
  toggleCaption(id: EditRowCell, current: any) {
    this.editCell = id;
  }

  getSelectedSubject(subjectv: any) {
    //console.log(subjectv);
  }
  changeValues(mark: number, header: EvaluationHeader, student: StudentListOnLecture, mId: number): void {
    let k = 0;
    if (mark <= header.maxEnterMark) {
      for (let i = 0; i < this.changedMarks.length; i++) {
        if (this.changedMarks[i].sTT == header.syllabusComponentTimeTableId && this.changedMarks[i].sd == student.ed) {
          this.changedMarks[i].mark = mark;
          k = 1;
        }
      }
      if (k == 0) {
        this.changedMarks.push({
          sd: student.ed, sE: null, mark: mark, sTT: header.syllabusComponentTimeTableId, calculationTypeID: header.calculationTypeID,
          evalutionTypeId: header.evalutionTypeId, maxEnterMark: header.maxEnterMark, maxMark: header.maxMark, maxRanking: header.maxRanking,
          minEnterMark: header.minEnterMark, componentOnSyllabusId: header.componentOnSyllabusId, maxCompCountOnWeeks: header.maxCompCountOnWeeks
        });
      }
      student.eM[mId] = mark;
    }
    else {
      student.eM[mId] = null;
      
      alert("თქვენ მიერ შეტანილი ქულის რაოდენობა სცილდება დასაშვებ ზღვარს!");
      this.cdr.detectChanges();
      //return null;
    }
  }
  SaveMarks(): void {
    if (confirm("გინდათ შეინახოთ მონაცემები")) {
      this.GraideService.SaveEvaluationMarks(this.changedMarks).then(e => {

        if (e.seccuss) {
          alert(this.captions.dataUpdatedSuccess);
        }
        else {
          alert(e.resultText);
        }
      });
      this.changedMarks.splice(0, this.changedMarks.length);
    }
  }
  courseId: number = 0;
  timeHeight: number = 0;
  filterPr: FilterGroups;
  Filter(filter: FilterGroups) {
    this.filterPr = filter;
    if (filter) {
      //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      if (this.courseId != filter.courseId) {
        this.GraideService.GetSyllabusData(filter.courseId).then(e => {
          this.syllabusData = e;
          if (e.syllabus.shedulerTypeID == 1) {
            this.timeHeight = 50;
          }
          else {
            this.timeHeight = 150;
          }
          this.SharedService.GetWeeksForSyllabus(this.syllabusData.syllabus.duration).then(e => { this.weeks = e; });
        });
        this.courseId == filter.courseId;
      }
      this.GraideService.GetStudentEvaluationListOnLecture(filter).then(e => {
        this.studentEvaluationGenerator = e;
        this.syllabusComponents.splice(0);
        this.FillSumMark(this.studentEvaluationGenerator);

        for (let i = 0; i < this.studentEvaluationGenerator.evaluationHeader.length; i++) {
          let n = 0;
          for (let k = 0; k < this.syllabusComponents.length; k++) {
            if (this.studentEvaluationGenerator.evaluationHeader[i].componentOnSyllabusId == this.syllabusComponents[k].componentOnSyllabusId) {
              n = 1;
              break;
            }
          }
          if (n == 0) {
            this.syllabusComponents.push(this.studentEvaluationGenerator.evaluationHeader[i]);
          }
        }
       // this.cdr.detectChanges();
        //this.cdr.detach();
      });

      // this.StudListService.StudentsList(filter.FilterPersone, 0, 0).then(e => { this.entitiesDataSource.data = (e as RetStudList).studentData; this.resultsLength = (e as RetStudList).count });
    }
  }


  FillSumMark(marks: StudentEvaluationGenerator) {
    for (let i = 0; i < marks.studentListOnLecture.length; i++) {
      let sum = this.SumStudentsGraids(marks.studentListOnLecture[i]);
    }
  }

  SumStudentsGraids(st: StudentListOnLecture): number {
    let sum = 0;
    for (let i = 0; i < st.eM.length; i++) {
      if (st.eM[i] != null && st.eM[i] > 0) {
        let header = this.studentEvaluationGenerator.evaluationHeader[i];
        sum += GradeFunctions.CalculatateMarkFromFormule(st.eM[i], header.maxCompCountOnWeeks,
          header.maxRanking,
          header.calculationTypeID,
          header.maxMark);
      }
    }

    st.sumMarks = (Math.round(sum * 100) / 100).toString();
    return sum;
  }

  //fileName = 'ExcelSheet.xlsx';  
  //printJournal() {
 
  //  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
  //  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //  XLSX.writeFile(wb, 'ScoreSheet.xlsx');  
  //}

  printJournal(format: string, type: number) {

    this.GraideService.GradeJournalCardPrint(this.filterPr, format, type);
  }

  RemoveCRaw(dataListName: string, row: any): void {
    if (confirm("გინდათ წაშალოთ ჩანაწერი!")) {
       if (dataListName == 'addWeekComponent') {
        row.operationStatus = 2;
        //var index = this.syllabusData.syllabusComponentOnWeeks.indexOf(row);
        //this.syllabusData.syllabusComponentOnWeeks.splice(index, 1);
      }
    }

  }


  weeks: any;
  syllabusData: SyllabusData;
  addCRow(dataListName: string): void {
    if (dataListName == 'addWeekComponent') {
      if (this.weeks == null) {
        this.SharedService.GetWeeksForSyllabus(this.syllabusData.syllabus.duration).then(e => { this.weeks = e });
      }
      let v = new SyllabusComponentOnWeek();
      this.syllabusData.syllabusComponentOnWeeks.push(v);
    }
  }


  ComponentQuantity(val: SyllabusComponentOnWeek) {
    var v = this.syllabusData.syllabusComponentOnWeeks.filter(e => e.componentOnSyllabusId == val.componentOnSyllabusId);
    var sylComponentQuantity = this.syllabusData.studyComponents.filter(e => e.id == val.componentOnSyllabusId);
    if (sylComponentQuantity != null && sylComponentQuantity.length > 0) {
      var weeks = sylComponentQuantity[0].maxCompCountOnWeeks;
      if (v != null && v.length > 0) {
        var sumData = 0;
        for (let i = 0; i < v.length; i++) {
          sumData += v[i].quantity;
        }

        if (sumData > weeks) {
          val.quantity = 0;
          alert("გადაჭარბება");
        }
      }
      else {
        if (val.quantity > weeks) {
          val.quantity = 0;
          alert("გადაჭარბება");
        }
      }
    }
    else {
      val.quantity = 0;
      alert("გადაჭარბება");
    }
  }

  SaveSyllabus(): void {
    this.GraideService.SaveSyllabusData(this.syllabusData).then(e => {
      if (e.seccuss) {
        alert("კომპონენტების განაწილება განახლებულია!");
      }
      else {
        alert("მოხდა შეცდომა!");
      }

    });
  }

  public innerWidth: any;
  ngOnInit() {
    // Add an event listener to window
    // Window can be defined in the pollyfiles.ts as: 
    // if (window) {
    //    (window as any).global = window;
    // }
    this.innerWidth = window.innerWidth;
    console.log(this.innerWidth);
    
    window.addEventListener('scroll', this.scroll, true); //third parameter
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  scrollPos(): void {
    var rightScrollPos = document.getElementById("table-scroll-tasks").scrollLeft;
    document.getElementById("table-scroll-employees").scrollLeft = rightScrollPos;
  }
  scroll = (event: any): void => {
    // Here scroll is a variable holding the anonymous function 
    // this allows scroll to be assigned to the event during onInit
    // and removed onDestroy
    // To see what changed:
    const number = event.srcElement.scrollTop;
    console.log(event);
    console.log('I am scrolling ' + number);
    var rightScrollPos = document.getElementById("table-scroll-tasks").scrollLeft;
    document.getElementById("table-scroll-employees").scrollLeft = rightScrollPos;
  };

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    console.log(this.innerWidth);
  }

  DownloadDocument(fileId: string) {
    this.SharedService.downloadFile(fileId);
  }

  trackByFn(index: any, item: any) {
    return index;
  }

}

import { Component, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../../identity/_models';


@Injectable()
export class ShearedDataService {
  private dataSource: BehaviorSubject<Object> = new BehaviorSubject(this.GetDefaultUserData());
    data = this.dataSource.asObservable();

 changeMode() {
     this.dataSource.next(this.GetDefaultUserData());
    }

  GetDefaultUserData(): object {
        if (localStorage.getItem('currentUser') != null) {
            let iu = JSON.parse(localStorage.getItem('currentUser')) as User;

          

          return iu;

        } else {
            return null;
        }
    }
}

<div class="_cs_flex _cs_size-w-percent--100">

  <div class="_cs_size-w-percent--100 _cs_p-left--lg _cs_p-right--lg _cs_p-down--lg ">



    <div class="sidebarbg__wrapper _cs_size-w-percent--100 _cs_flex _cs_flex-d--column">
      <div class="_cs_m-top--xxl _cs_p-top--xxl">
        <div *ngIf="informationBoardResult" class="_cs_flex _cs_flex-d--column">
          <div class="_cs_flex _cs_flex-wrap">
            <div class="_cs_p-left--sm _cs_p-right--sm _cs_m-top--xl _cs_col-md-8 _cs_col-sm-12 _cs_col-xs-12">

              <div class="_cs_m-down--lg">
                <div
                  class="_cs_p-xs _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_border-down--primary-6 _cs_flex _cs_flex-a--center">
                  <span
                    class="_cs_icon _cs_icon-xl _cs_shadow-primary-6-0--2 _cs_border-radius--full _cs_border--primary-6 _cs_m-right--md">
                    <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-Semester-report"></span>


                  </span>
                  <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold _cs_color-primary-6">{{captions.semesterReport}}</span>
                </div>
              </div>
              <div>
                <div
                  class="_cs_flex _cs_flex-wrap _cs_size-w-percent--100  _cs_position-relative _cs_color-bg--primary-5 _cs_shadow-primary-0-0--1 _cs_p-sm _cs_border-radius--sm">
                  <div
                    class="_cs_col-xs-12 _cs_col-sm-4 _cs_col-md-4 _cs_flex _cs_flex-j--center _cs_p-top--md _cs_p-down--md">
                    <div class="the-chart" style="display: block;float:left; font-size:10px"
                      [style.height.px]="dynHeight" [style.width.px]='dynWidth' *ngIf="isDataLoaded">
                      <canvas class="_cs_label _cs_label-sm _cs_label-font-setting-case-on" baseChart
                        [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
                        [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend"></canvas>
                    </div>
                  </div>
                  <div
                    class="_cs_col-xs-12 _cs_col-sm-4 _cs_col-md-4 _cs_flex _cs_flex-j--center _cs_p-top--md _cs_p-down--md">
                    <div class="the-chart" style="display: block;float:left;font-size:10px"
                      [style.height.px]="dynHeight" [style.width.px]='dynWidth' *ngIf="isDataLoaded">
                      <canvas class="_cs_label _cs_label-sm _cs_label-font-setting-case-on" baseChart
                        [data]="pieChartData1" [labels]="pieChartLabels1" [chartType]="pieChartType"
                        [options]="pieChartOptions1" [plugins]="pieChartPlugins" [legend]="pieChartLegend"></canvas>
                    </div>
                  </div>
                  <div
                    class="_cs_col-xs-12 _cs_col-sm-4 _cs_col-md-4 _cs_flex _cs_flex-j--center _cs_p-top--md _cs_p-down--md">
                    <div class="the-chart" style="display: block;float:left;font-size:10px"
                      [style.height.px]="dynHeight" [style.width.px]='dynWidth' *ngIf="isDataLoaded">
                      <canvas class="_cs_label _cs_label-sm _cs_label-font-setting-case-on" baseChart
                        [data]="pieChartData2" [labels]="pieChartLabels2" [chartType]="pieChartType"
                        [options]="pieChartOptions2" [plugins]="pieChartPlugins" [legend]="pieChartLegend"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="_cs_p-left--sm _cs_p-right--sm _cs_col-md-4 _cs_col-sm-12 _cs_col-xs-12">
              <div class="_cs_m-down--lg _cs_m-top--xl">
                <div
                  class="_cs_p-xs _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_border-down--primary-6 _cs_flex _cs_flex-a--center">
                  <span
                    class="_cs_icon _cs_icon-xl _cs_shadow-primary-6-0--2 _cs_border-radius--full _cs_border--primary-6 _cs_m-right--md">
                    <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-news"></span>


                  </span>
                  <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold _cs_color-primary-6">{{captions.news}}</span>
                </div>
              </div>
              <table class=" table table-bordered">
                <thead>
                  <tr>
                    <th>
                      {{captions.title}}
                    </th>
                    <th>
                      {{captions.date}}
                    </th>
                    <th>

                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let v of informationBoardResult.informationBoardList">
                    <ng-container *ngIf="v.infoTypeId==1">
                      <td>{{v.headerText}}</td>
                      <td>{{v.publishDateTime}}</td>
                      <td>
                        <div *ngIf="v.linkUrl!=null&&v.linkUrl.length>9" class="">
                          <a class="_cs_button _cs_button-sm _cs_button-border--positive _cs_size-w-percent--100"
                            [href]="v.linkUrl" target="_blank">
                            <span class="_cs_label _cs_label-sm _cs_label-500 _cs_label-font-setting-case-on _cs_label-t-u">{{captions.readMore}}</span>
                          </a>
                        </div>
                        <div *ngIf="!(v.linkUrl!=null&&v.linkUrl.length>9)" class="">
                          <button href="#" (click)="OpenFullData(v)"
                            class="_cs_button _cs_button-sm _cs_button-border--positive _cs_size-w-percent--100">
                            <span class="_cs_label _cs_label-sm _cs_label-500 _cs_label-font-setting-case-on _cs_label-t-u">{{captions.readMore}}</span>
                          </button>
                        </div>
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="_cs_flex _cs_flex-wrap">
            <div class="_cs_col-md-4 _cs_col-sm-12 _cs_col-xs-12 _cs_p-left--sm _cs_p-right--sm">
              <div class="_cs_size-w-percent--100">
                <div class="_cs_m-down--lg _cs_m-top--xl">
                  <div
                    class="_cs_p-xs _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_border-down--primary-6 _cs_flex _cs_flex-a--center">
                    <span
                      class="_cs_icon _cs_icon-xl _cs_shadow-primary-6-0--2 _cs_border-radius--full _cs_border--primary-6 _cs_m-right--md">
                      <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-Announcements"></span>


                    </span> <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold _cs_color-primary-6">{{captions.announcements}}</span>
                  </div>
                </div>
                <table class=" table table-bordered">
                  <thead>
                    <tr>
                      <th>
                        {{captions.title}}
                      </th>
                      <th>
                        {{captions.date}}
                      </th>
                      <th>

                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let v of informationBoardResult.informationBoardList">
                      <ng-container *ngIf="v.infoTypeId==2">
                        <td>{{v.headerText}}</td>
                        <td>{{v.publishDateTime}}</td>
                        <td>
                          <div *ngIf="v.linkUrl!=null&&v.linkUrl.length>9" class="">
                            <a class="_cs_button _cs_button-sm _cs_button-border--positive _cs_size-w-percent--100"
                              [href]="v.linkUrl" target="_blank">
                              <span class="_cs_label _cs_label-sm _cs_label-500 _cs_label-font-setting-case-on _cs_label-t-u">{{captions.readMore}}</span>
                            </a>
                          </div>
                          <div *ngIf="!(v.linkUrl!=null&&v.linkUrl.length>9)" class="">
                            <button href="#" (click)="OpenFullData(v)"
                              class="_cs_button _cs_button-sm _cs_button-border--positive _cs_size-w-percent--100">
                              <span class="_cs_label _cs_label-sm _cs_label-500 _cs_label-font-setting-case-on _cs_label-t-u">{{captions.readMore}}</span>
                            </button>
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="_cs_col-md-4 _cs_col-sm-12 _cs_col-xs-12 _cs_p-left--sm _cs_p-right--sm">
              <div class="_cs_size-w-percent--100">
                <div class="_cs_m-down--lg _cs_m-top--xl">
                  <div
                    class="_cs_p-xs _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_border-down--primary-6 _cs_flex _cs_flex-a--center">
                    <span
                      class="_cs_icon _cs_icon-xl _cs_shadow-primary-6-0--2 _cs_border-radius--full _cs_border--primary-6 _cs_m-right--md">
                      <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-conference"></span>


                    </span> <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold _cs_color-primary-6">{{captions.trainingsConferences}}</span>
                  </div>
                </div>
                <table class=" table table-bordered">
                  <thead>
                    <tr>
                      <th>
                        {{captions.title}}
                      </th>
                      <th>
                        {{captions.date}}
                      </th>
                      <th>

                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let v of informationBoardResult.informationBoardList">
                      <ng-container *ngIf="v.infoTypeId==3">
                        <td>{{v.headerText}}</td>
                        <td>{{v.publishDateTime}}</td>
                        <td>
                          <div *ngIf="v.linkUrl!=null&&v.linkUrl.length>9" class="">
                            <a class="_cs_button _cs_button-sm _cs_button-border--positive _cs_size-w-percent--100"
                              [href]="v.linkUrl" target="_blank">
                              <span class="_cs_label _cs_label-sm _cs_label-500 _cs_label-font-setting-case-on _cs_label-t-u">{{captions.readMore}}</span>
                            </a>
                          </div>
                          <div *ngIf="!(v.linkUrl!=null&&v.linkUrl.length>9)" class="">
                            <button href="#" (click)="OpenFullData(v)"
                              class="_cs_button _cs_button-sm _cs_button-border--positive _cs_size-w-percent--100">
                              <span class="_cs_label _cs_label-sm _cs_label-500 _cs_label-font-setting-case-on _cs_label-t-u">{{captions.readMore}}</span>
                            </button>
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="_cs_col-md-4 _cs_col-sm-12 _cs_col-xs-12 _cs_p-left--sm _cs_p-right--sm">
              <div class="_cs_size-w-percent--100">
                <div class="_cs_m-down--lg _cs_m-top--xl">
                  <div
                    class="_cs_p-xs _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_border-down--primary-6 _cs_flex _cs_flex-a--center">
                    <span
                      class="_cs_icon _cs_icon-xl _cs_shadow-primary-6-0--2 _cs_border-radius--full _cs_border--primary-6 _cs_m-right--md">
                      <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-guide"></span>


                    </span>
                    <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold _cs_color-primary-6">{{captions.guide}}</span>
                  </div>
                </div>
                <table class=" table table-bordered">
                  <thead>
                    <tr>
                      <th>
                        {{captions.title}}
                      </th>
                      <!--<th>
                          თარიღი
                        </th>-->
                      <th>

                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let v of informationBoardResult.informationBoardList">
                      <ng-container *ngIf="v.infoTypeId==4">
                        <td>{{v.headerText}}</td>
                        <!--<td>{{v.publishDateTime}}</td>-->
                        <td>
                          <div *ngIf="v.linkUrl!=null&&v.linkUrl.length>9" class="">
                            <a class="_cs_button _cs_button-sm _cs_button-border--positive _cs_size-w-percent--100"
                              [href]="v.linkUrl" target="_blank">
                              <span class="_cs_label _cs_label-sm _cs_label-500 _cs_label-font-setting-case-on _cs_label-t-u">{{captions.readMore}}</span>
                            </a>
                          </div>
                          <div *ngIf="!(v.linkUrl!=null&&v.linkUrl.length>9)" class="">
                            <button href="#" (click)="OpenFullData(v)"
                              class="_cs_button _cs_button-sm _cs_button-border--positive _cs_size-w-percent--100">
                              <span class="_cs_label _cs_label-sm _cs_label-500 _cs_label-font-setting-case-on _cs_label-t-u">{{captions.readMore}}</span>
                            </button>
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!--<div *ngIf="informationBoardResult" class="_cs_row">

          <div *ngFor="let v of informationBoardResult.informationBoardList"
               class="_cs_col-xs-3 _cs_p-left--md _cs_p-right--md _cs_hover _cs_m-down--lg">
            <div class="_cs_border-radius--sm _cs_overflow-hidden _cs_color-bg--primary-5 _cs_shadow-primary-0-0--1
              _cs_hoveritem-shadow-primary-0-0--2 _cs_transition-0--2 _cs_size-h-min-px--450 _cs_flex _cs_flex-d--column">

              <div class="_cs_flex _cs_size-w-percent--100 _cs_position-relative">
                <ng-container class="_cs_size-w-percent--100" *ngFor="let f of v.informationBoardDataFiles">
                  <ProfileImage class="_cs_size-w-percent--100 _cs_child-img-w--full  _cs_size-h-max-px--250 _cs_overflow-hidden"
                                *ngIf="f.isAvatar" [fileId]="BehavRet(f.fileStreamId)">

                  </ProfileImage>
                </ng-container>
                <div class="_cs_position-absolute _cs_position-right-percent--0 _cs_p-sm _cs_p-top--xs _cs_p-down--xs
                       _cs_position-down-percent--0 _cs_color-bg--primary-6 _cs_border-radius-left-top--sm">
                  <span class="_cs_label _cs_label-sm _cs_label-500 _cs_label-font-setting-case-on _cs_color-primary-5">{{v.publishDateTime}}</span>
                </div>
              </div>

              <div class="_cs_p-lg _cs_p-down--sm">
                <span class="_cs_label _cs_label-lg _cs_label-t-u _cs_label-500 _cs_label-font-setting-case-on _cs_color-primary-2">{{v.headerText}}</span>

              </div>

              <div class="_cs_p-left--lg  _cs_p-right--lg">
                <div class="_cs_p-down--lg">
                  <span class="_cs_label _cs_label-sm _cs_color-primary-2">{{v.shortText}}</span>
                </div>
              </div>
              <div *ngIf="v.linkUrl!=null&&v.linkUrl.length>9"
                   class="">
                <a class="_cs_button _cs_button-sm _cs_button-border--positive _cs_size-w-percent--100"
                   [href]="v.linkUrl" target="_blank">ს
                  <span class="_cs_label _cs_label-sm _cs_label-500 _cs_label-font-setting-case-on _cs_label-t-u">ვრცლად</span>
                </a>
              </div>
              <div *ngIf="!(v.linkUrl!=null&&v.linkUrl.length>9)"
                   class="">
                <button href="#" (click)="OpenFullData(v)"
                        class="_cs_button _cs_button-sm _cs_button-border--positive _cs_size-w-percent--100">
                  <span class="_cs_label _cs_label-sm _cs_label-500 _cs_label-font-setting-case-on _cs_label-t-u">ვრცლად</span>
                </button>
              </div>



            </div>
          </div>
        </div>-->
      <!--<div class="_cs_m-top--auto">
          <mat-paginator class="mat-paginator  _cs_shadow-primary-0-0--1 _cs_border-radius--sm"
                         [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons #paginator [length]="resultsLength">
          </mat-paginator>
        </div>-->
    </div>
    <!--</div>-->
  </div>
</div>

export class Syllabus {
  public sylabusId: number;
  public subjectId: number;
  public sylabusCaption: Array<SylabusHeader>;
  public subjectCaption: string;
  public credit: number;
  public fakulty: string;
  public facultyId: number;
  public studyLevelId: number;
  public studyLevelName: string;
  public typeName: string;
  public typeId: number;
  public createDate: Date;
  public status: string;
  public statusId: number;
  public shedulerTypeID: number;
  public shedulerTypeName: string;
  public duration: number;
  public maxGrade: number;
  public minFinalExamGrade: number;
  public learnLanguage: number;
  public learnLanguageCaption: string;
}
export class SylabusHeader {
  public headerId: number;
  public caption: string;
  public language: string;
  public languageId: number;
}
export class PreconditionStructure {
  public id: number;
  public parentId: number;
  public preconGroupName: string;
  public preconditionStructureTypeId: number;
  public preconditionStructureTypeName: string;
  public subPreconditions: Array<SubPrecondition>;
}
export class SubPrecondition {
  public id: number;
  public preconStructureId: number;
  public subjectId: number;
  public subjectCaption: string;
  public sylabusId: number;
  public sylabusCaption: string;
  public semester: number;
  public programId: number;
  public programName: string;
  public directionId: number;
  public directionName: string;
  public fakultyId: number;
  public fakultyName: string;
  public doneCredits: number;
  public minAvggrade: number;
  public preconditionTypeId: number;
  public preconditionTypeName: string;
}
export class Lecturer {
  public id: number;
  public syllabusId: number;
  public personId: number;
  public sName: string;
  public fName: string;
}
export class StudyComponent {
  public id: number;
  public parentId: number;
  public componentTypeId: number;
  public componentTypeName: string;
  public evalutionTypeId: number;
  public evalutionTypeName: string;
  public lectureTypeId: number;
  public lectureTypeName: string;
  public scoreEditorTypeId: number;
  public maxMark: number;
  public minMark: number;
  public maxEnterMark: number;
  public minEnterMark: number;
  public weekGenerate: boolean;
  public compOrder: number;
  public calculationTypeId: number;
  public calculationTypeName: string;
  public maxRanking: number;
  public maxCompCountOnWeeks: number;
  public createTime: Date;
}
export class Lecture {
  public id: number;
  public syllabusId: number;
  public lectureTypeId: number;
  public duration: number;
  public lectureTypeName: string;
  public checkedComp: boolean;
}

export class SyllabusLinkToCurriculum {
  public curriculumSubjectID: string;
  public curriculumPath: string;
  public credit: number;
  public isInProgram: boolean;
}


export class SyllabusFiles {
  public id: number;
  public syllabusDocumentTypeId: number;
  public fileId: string;
  public fileName: string
  public syllabusDocumentTypeCaption: string;
}

export class SyllabusData {
  public syllabus: Syllabus;
  public preconditionStructure: Array<PreconditionStructure>;
  public lecturers: Array<Lecturer>;
  public studyComponents: Array<StudyComponent>;
  public lectures: Array<Lecture>;
  public syllabusLinkToCurriculum: Array<SyllabusLinkToCurriculum>;
  public syllabusFiles: Array<SyllabusFiles>;
  public syllabusComponentOnWeeks: Array<SyllabusComponentOnWeek>;
}

export class SyllabusComponentOnWeek {
  public id: number | null;
  public weekId: number | null;
  public dayId: number | null;
  public quantity: number;
  public weekName: string | null;
  public componentName: string;
  public componentOnSyllabusId: number;
  public operationStatus: number | null;
}

export enum PreconditionFor {
  syllabus = 1,
  eduGroup = 2,
  curriculum = 3
}

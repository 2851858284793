import { Injectable } from '@angular/core';
import { Caption, CaptionGE, CaptionENG } from '../LanguageModel/Caption';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {


  georgian: CaptionGE = new CaptionGE();
  english: CaptionENG = new CaptionENG();

  getCaption(languageId: number): Caption {

    if (languageId == 1) {
      return this.georgian;
    } else if (languageId == 2) {
      return this.english;
    }
    return null;
  }

}

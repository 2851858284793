import { Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { CardService } from '../Service/CardService';
import { LanguageService } from '../../Service/LanguageService';
import { Caption, PortalLanguageCaption } from '../../LanguageModel/Caption';
import { LecturerTimeTableOnLecture } from '../../Models/LecturerLoad';
import { SharedService } from '../Service/SharedService';
import { FieldModel, EventSettingsModel, DayService, WeekService, WorkWeekService, MonthService, AgendaService, ResizeService, DragAndDropService, EventRenderedArgs, ScheduleComponent, TimeScaleModel } from '@syncfusion/ej2-angular-schedule';
import { extend, Internationalization } from '@syncfusion/ej2-base';




@Component({
  selector: 'TimeTable',
  templateUrl: 'TimeTable.html',
  styleUrls: ['TimeTable.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService, ResizeService, DragAndDropService]
})







export class TimeTable implements OnInit {
  public captions: Caption;
  public audList: any;
  public timeTableOut: Array<LecturerTimeTableOnLecture>;
  weekDayList: any;
  public selectedDate: Date = new Date(Date.now());
  public eventSettings: EventSettingsModel;
  public timeScale: TimeScaleModel = {
    enable: true,
    //interval: 60,
    //slotCount: 1,
    majorSlotTemplate: '#majorSlotTemplate'//,
    //minorSlotTemplate: '#minorSlotTemplate'
  };
  @ViewChild('schedule', { static: false })
  public scheduleObj: ScheduleComponent;
  language: number;

  constructor(
    private activatedRoute: ActivatedRoute, private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    public CardService: CardService,
    public SharedService: SharedService,
    public LanguageService: LanguageService,
    private http: HttpClient) {
    this.language = parseInt(localStorage.getItem("language"));
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];

    

  }
  ngOnInit() {
    this.SharedService.GetClassRooms().then(e => {
      this.audList = e
    });
    this.CardService.GetCurrentSessionTimeTable().then(e => {
     
      let timeTable = e as Array<LecturerTimeTableOnLecture>;

      let scheduleData = new Array<Object>();
      for (let i = 0; i < timeTable.length; i++) {
        let localData = new Date();

        var currentDay = localData.getDay();
        if (timeTable[i].weekId != null) {
          var distance = timeTable[i].weekId - currentDay;
          localData.setDate(localData.getDate() + distance);
        }

        let timeStart = timeTable[i].startTime != null ? new Date(localData.getFullYear(), localData.getMonth(), localData.getDate(), parseInt(timeTable[i].startTime.substring(0, 2)), parseInt(timeTable[i].startTime.substring(3, 5))) : timeTable[i].date;
        let timeEnd = new Date(localData.getFullYear(), localData.getMonth(), localData.getDate(), parseInt(timeTable[i].endTime.substring(0, 2)), parseInt(timeTable[i].endTime.substring(3, 5)));
        let dateEnd = timeTable[i].startTime == null ? new Date((new Date(timeTable[i].date)).getFullYear(), (new Date(timeTable[i].date)).getMonth(), (new Date(timeTable[i].date)).getDate(), parseInt(timeTable[i].endTime.substring(0, 2)), parseInt(timeTable[i].endTime.substring(3, 5))) : null;
        console.log(dateEnd);

        if (timeTable[i].startTime == null) {
          scheduleData.push({
            Id: (i + 1),
            Subject: timeTable[i].syllabusName,
            Location: this.ClassRoomRootShow(timeTable[i].classRoomsId),
            Description: this.captions.lectureType+ ":" + timeTable[i].lectureTypeName,
            StartTime: timeTable[i].date != null ? (new Date(timeTable[i].date)) : timeStart,
            EndTime: dateEnd != null ? dateEnd : timeEnd,
            CategoryColor: '#f57f17'
          });
        }
        else {
          for (let k = 0; k < 6; k++) {
            let start = timeTable[i].startTime != null ? new Date(localData.getFullYear(), localData.getMonth(), localData.getDate() + (k * 7), parseInt(timeTable[i].startTime.substring(0, 2)), parseInt(timeTable[i].startTime.substring(3, 5))) : timeTable[i].date;
            let end = new Date(localData.getFullYear(), localData.getMonth(), localData.getDate() + (k * 7), parseInt(timeTable[i].endTime.substring(0, 2)), parseInt(timeTable[i].endTime.substring(3, 5)));
            scheduleData.push({
              Id: (i + k + 1),
              Subject: timeTable[i].syllabusName,
              Location: this.ClassRoomRootShow(timeTable[i].classRoomsId),
              Description: this.captions.lectureType + ":" + timeTable[i].lectureTypeName,
              StartTime: start,
              EndTime: end,
              CategoryColor: '#f57f17'
            });
          }
        }

      }


      let weeks = new Array<any>();

      for (let i = 0; i < timeTable.length; i++) {
        let addWeek = true;
        for (let w = 1; w < weeks.length; w++) {
          if (weeks[w] == timeTable[i].dayName) {
            addWeek = false;
          }
        }
        if (addWeek) {
          weeks.push(timeTable[i].dayName)
        }

        if (timeTable[i].startTime != null && timeTable[i].endTime != null) {
          var startTimeOut = timeTable[i].startTime.split(":");
          var startNumOut = (parseInt(startTimeOut[0])) * 60 + (parseInt(startTimeOut[1]));
          var endTimeOut = timeTable[i].endTime.split(":");
          var endNumOut = (parseInt(startTimeOut[0])) * 60 + (parseInt(startTimeOut[1]));

          for (let k = 0; k < timeTable.length; k++) {

            if (timeTable[i].timetableId != timeTable[k].timetableId && timeTable[i].dayName == timeTable[k].dayName) {
              if (timeTable[k].startTime != null && timeTable[k].endTime != null) {
                var startTimeIn = timeTable[k].startTime.split(":");
                var startNumIn = (parseInt(startTimeIn[0])) * 60 + (parseInt(startTimeIn[1]));
                var endTimeIn = timeTable[k].endTime.split(":");
                var endNumIn = (parseInt(endTimeIn[0])) * 60 + (parseInt(endTimeIn[1]));

                if ((startNumOut > startNumIn && startNumOut < endNumIn) || (endNumOut < endNumIn && endNumOut > startNumIn)
                  || (startNumOut < startNumIn && endNumOut > endNumIn)) {
                  timeTable[k].timeEquals = true;
                  timeTable[i].timeEquals = true;
                  console.log(timeTable[k]);
                }
              }

            }

          }
        }

      }


      this.eventSettings = {
        dataSource: <Object[]>extend([], scheduleData, null, true), allowEditing: false, allowAdding: false, allowDeleting: false, enableTooltip: true,
      };
      this.timeTableOut = timeTable;

      this.weekDayList = weeks;
    });
  }


  public instance: Internationalization = new Internationalization();
  getMajorTime(date: Date): string {
    return this.instance.formatDate(date, { skeleton: 'Hm' });
  }
  getMinorTime(date: Date): string {
    return this.instance.formatDate(date, { skeleton: 'ms' }).replace(':00', '');
  }
  onEventRendered(args: EventRenderedArgs): void {
    let categoryColor: string = args.data.CategoryColor as string;
    if (!args.element || !categoryColor) {
      return;
    }
    if (this.scheduleObj.currentView === 'Agenda') {
      (args.element.firstChild as HTMLElement).style.borderLeftColor = categoryColor;
    } else {
      args.element.style.backgroundColor = categoryColor;
    }
  }

  ClassRoomRootShow(classRoomId: number): string {

    let parentId = 0;
    let parentRoom = "";
    for (let i = 0; i < this.audList.length; i++) {
      if (this.audList[i].id == classRoomId) {
        parentId = this.audList[i].parentid;
        parentRoom = this.audList[i].text;
        break;
      }
    }
    let v = new Array<string>();
    this.ClassRoomParentShow(parentId, v);
    let retS = "";
    for (let i = (v.length - 1); i >= 0; i--) {
      retS += v[i] + "/";
    }

    retS += parentRoom;
    return retS;
  }
  ClassRoomParentShow(classRoomId: number, v: Array<string>): void {

    for (let i = 0; i < this.audList.length; i++) {
      if (this.audList[i].id == classRoomId) {
        v.push(this.audList[i].text);
        this.ClassRoomParentShow(this.audList[i].parentid, v);
        break;
      }
    }
  }
}

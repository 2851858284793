import { Injectable } from '@angular/core';
//import { Headers, Http, Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { map } from 'rxjs/operators';
import { map, tap } from 'rxjs/operators';
//import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs';
import { UIfilter } from '../../../Models/filters';
import { CorrespondenceFilter, RetStatementList, CorrespondenceDataWithAttachmentGet, CorrespondenceDataWithAttachmentSend, CorrespondencStatement, CorrespondeceTypesData } from '../../../Models/Correspondence';
import { ReturnResultValue } from '../../../Models/ResultReturnParameters';


@Injectable()
export class CorrespondenceService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private headers2 = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
  private heroesUrl = '/';
  constructor(private http: HttpClient) { }

  GetIncomingOutgonigCorrespondence(fl: CorrespondenceFilter): Promise<RetStatementList> {

    //let body = {
    //  filter: fl
    //};
    return this.http.post("/api/Correspondence/GetIncomingOutgonigCorrespondence", fl, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  UnReadCorrespondence(): Promise<Array<CorrespondencStatement>> {

    return this.http.post("/api/Correspondence/UnReadCorrespondence", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetCorrespondence(correspondenceId: number): Promise<CorrespondenceDataWithAttachmentGet> {

    return this.http.post("/api/Correspondence/GetCorrespondence", correspondenceId, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }


  SendCorrespondence(correspondecDataToSend: CorrespondenceDataWithAttachmentSend): Promise<ReturnResultValue> {

    return this.http.post("/api/Correspondence/SendCorrespondence", correspondecDataToSend, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }


    GetCorrespondeceTypes(): Promise<Array<CorrespondeceTypesData>> {

    return this.http.post("/api/Correspondence/GetCorrespondeceTypes", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetCorrespondeceImportantStatus(): Promise<any> {

    return this.http.post("/api/Correspondence/GetCorrespondeceImportantStatus", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetCorState(): Promise<any> {

    return this.http.post("/api/Correspondence/GetCorState", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}

import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { map } from 'rxjs/operators';
import { map, tap } from 'rxjs/operators';
//import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs';
import { SaveEvaluationMarks, FilterGroups, StudentEvaluationGenerator, StudentListOnLectureForView } from '../../Models/Graids';
import { SyllabusData } from '../../Models/SyllabusRetData';
import { ReturnResultValue } from '../../Models/ResultReturnParameters';



@Injectable()
export class GraideService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private headers2 = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
  private heroesUrl = '/';
  constructor(private http: HttpClient) { }


  
  GetStudentEvaluationListOnLecture(parameters: FilterGroups): Promise<StudentEvaluationGenerator> {

    return this.http.post("/api/StudentEvaluation/GetStudentEvaluationListOnLecture", parameters, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetStudentListOnLecture(parameters: FilterGroups): Promise<Array<StudentListOnLectureForView>> {

    return this.http.post("/api/StudentEvaluation/GetStudentListOnLecture", parameters, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
    public GradeJournalCardPrint(parameters: FilterGroups, format: string, type: number) {
        window.open(`/api/StudentEvaluation/StudentEvaluationListOnLecturePrint?courseId=${parameters.courseId}&groupId=${parameters.groupId}&lacureTypeId=${parameters.lacureTypeId}&format=${format}&type=${type}`, 'Download');
    }

  SaveEvaluationMarks(marks: Array<SaveEvaluationMarks>): Promise<ReturnResultValue> {

    return this.http.post("/api/StudentEvaluation/SaveEvaluationMarks", marks, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
  GetLecturerGroupsList() {
    return this.http.post("/api/StudentEvaluation/GetLecturerGroupsList", { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetSyllabusData(syllabusId:number): Promise<SyllabusData> {
    return this.http.post("/api/StudentEvaluation/GetSyllabusData", syllabusId ,{ headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
  SaveSyllabusData(syllabus: SyllabusData): Promise<ReturnResultValue> {

    return this.http.post("/api/StudentEvaluation/SaveSyllabusData", syllabus, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }


  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}

import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
//import { map } from 'rxjs/operators';
import { map, tap } from 'rxjs/operators';
//import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs';
import { IUserResponse, User } from '../../Models/Users';

import { FilterStudentGroup } from '../../Models/Course';
import { ILinkSubjectResponse } from '../../Models/SyllabusData';
import { EduSession } from '../../Models/EduSession';
import { FilterByStudyElements } from '../../Models/filters';


@Injectable()
export class SharedService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private headers2 = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
  private heroesUrl = '/';
  constructor(private http: HttpClient) { }


  GetPreconditionTypes(): Promise<any> {

    //let body = {
    //  Id: sylabusId
    //};
    return this.http.post("/api/SylabusPrecondition/GetPreconditionTypes", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetProgramElementTypes(structTypeId:number): Promise<any> {

    return this.http.post("/api/Sheared/GetProgramElementTypes",  structTypeId, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
  GetEduSessionBySyllabusId(syllabusId: number): Promise<any> {

    return this.http.post("/api/Sheared/GetEduSessionBySyllabusId", syllabusId, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetEduSessionByStudentId(studentId: number): Promise<Array<EduSession>> {

    return this.http.post("/api/Sheared/GetEduSessionByStudentId", studentId, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
  GetElementStatusList(): Promise<any> {

    return this.http.post("/api/Sheared/GetElementStatusList", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetCurrentSession(vl: FilterByStudyElements): Promise<EduSession> {

    return this.http.post("/api/Sheared/GetCurrentSession", vl, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }



  GetSheduleType(): Promise<any> {

    return this.http.post("/api/Sheared/GetSheduleType", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetProgramTypes(): Promise<any> {
    return this.http.post("/api/Sheared/GetProgramTypes", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
  GetSyllabusTypes(): Promise<any> {
    return this.http.post("/api/Sheared/GetSyllabusTypes", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetPreconditionGroupTypes(): Promise<any> {

    //let body = {
    //  Id: sylabusId
    //};
    return this.http.post("/api/SylabusPrecondition/GetPreconditionStuctureTypes", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetFakultyList(): Promise<any> {

    //let body = {
    //  Id: sylabusId
    //};
    return this.http.post("/api/Sheared/GetFakultyList", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }


  GetLectureTypeList(): Promise<any> {

    //let body = {
    //  Id: sylabusId
    //};
    return this.http.post("/api/Sheared/GetLectureTypeList", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }


  GetWeekDaysList(): Promise<any> {

    //let body = {
    //  Id: sylabusId
    //};
    return this.http.post("/api/Sheared/GetWeekDaysList", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }


  GetDirectionList(Id:any): Promise<any> {
    var pr = { Id: Id ,TypeID:0};
    return this.http.post("/api/Sheared/GetDirectionList", pr, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetStudentsGroupOnLecturer(fl: FilterStudentGroup): Promise<any> {
    return this.http.post("/api/Sheared/GetStudentsGroupOnLecturer", fl, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }


  GetStudyLevelList(): Promise<any> {

    //let body = {
    //  Id: sylabusId
    //};
    return this.http.post("/api/Sheared/GetStudyLevelList", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetLanguage(): Promise<any> {

    return this.http.post("/api/Sheared/GetLanguage", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetStudyYears(): Promise<any> {

    return this.http.post("/api/Sheared/GetStudyYears", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetStudySemesters(): Promise<any> {

    return this.http.post("/api/Sheared/GetStudySemesters", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetStudyProgRecomendedStudySemesters(): Promise<any> {

    return this.http.post("/api/Sheared/GetStudyProgRecomendedStudySemesters", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetClassRooms(): Promise<any> {

    return this.http.post("/api/Sheared/GetClassRooms", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }


  GetLecturerPersoneList(filter: { name: string } = { name: '' }, page = 1): Observable<IUserResponse> {
    var pr = { value: filter.name, num: page };
    if (filter.name.length > 1) {
      return this.http.post<IUserResponse>("/api/Sheared/GetLecturerPersoneList", pr, { headers: this.headers });
    }
    else {
      let ob = new Observable<IUserResponse>();
      return ob;
    }
  }
  GetFilteredSubjectList(filter: { name: string, studylevelId: number, facultyId: number } = { name: '', studylevelId: -1, facultyId: -1 }, page = 1): Observable<ILinkSubjectResponse> {
    var pr = { value: filter.name, studylevelId: filter.studylevelId, facultyId: filter.facultyId, num: page };
    if (filter.name.length > 1) {
      return this.http.post<ILinkSubjectResponse>("/api/Sheared/GetFilteredSubjectList", pr, { headers: this.headers });
    }
    else {
      let ob = new Observable<ILinkSubjectResponse>();
      return ob;
    }
  }

  GetFilteredCourseList(filter: { name: string, studylevelId: number, facultyId: number, studyYear: number, studySemester: number } = { name: '', studylevelId: -1, facultyId: -1, studyYear: -1, studySemester:-1 }, page = 1): Observable<ILinkSubjectResponse> {
    var pr = { value: filter.name, studylevelId: filter.studylevelId, facultyId: filter.facultyId, studyYear: filter.studyYear, studySemester: filter.studySemester, num: page };
    if (filter.name.length > 1) {
      console.log(pr);
      return this.http.post<ILinkSubjectResponse>("/api/Sheared/GetFilteredCourseList", pr, { headers: this.headers });
    }
    else {
      let ob = new Observable<ILinkSubjectResponse>();
      return ob;
    }
  }


  GetEduStatus(): Promise<any> {

    return this.http.post("/api/Sheared/GetEduStatus", null, { headers: this.headers })
        .toPromise()
        .then(response => response)
        .catch(this.handleError);
  }

  GetWorkerStatus(): Promise<any> {

    return this.http.post("/api/Sheared/GetWorkerStatus", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetFinStatus(): Promise<any> {

    return this.http.post("/api/Sheared/GetFinStatus", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
  public upload(data, userId) {


    return this.http.post<any>("/api/Sheared/UploadDocument", data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

  public downloadFile(fileId) {
    window.open("/api/Sheared/FileDownload?fileInfo=" + fileId, 'Download');

  }

  public DeleteDocuments(fileId) {
    return this.http.post("/api/Sheared/FileDownload", fileId, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }



  getFullImage(fileId): Promise<any> {
    let url = "/api/Sheared/FileImage?fileInfo=" + fileId;
    return this.http.get(url, { headers: this.headers }).toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
  getImage(fileId: string): Observable<Blob> {
    let url = "/api/Sheared/FileImage?fileInfo=" + fileId;
    return this.http.get(url, { responseType: 'blob' });
  }

  GetOrderTypeGroupCategories(objectFor: number): Promise<any> {

    return this.http.post("/api/Sheared/GetOrderTypeGroupCategories", objectFor, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetPersonType(): Promise<any> {

    return this.http.post("/api/Sheared/GetPersonType", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetStudyProgramList(filter: FilterByStudyElements): Promise<any> {

    return this.http.post("/api/Sheared/GetStudyProgramList", filter, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }


  GetFileFor(): Promise<any> {

    return this.http.post("/api/Sheared/GetFileFor", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetContactCategories(): Promise<any> {

    return this.http.post("/api/Sheared/GetContactCategories", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
  GetSex(): Promise<any> {

    return this.http.post("/api/Sheared/GetSex", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetEduProcessType(): Promise<any> {

    return this.http.post("/api/Sheared/GetEduProcessType", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetEditionDataTypes(): Promise<any> {

    return this.http.post("/api/Sheared/GetEditionDataTypes", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
  GetEditionDataList(editionDataTypesId: number): Promise<any> {

    return this.http.post("/api/Sheared/GetEditionDataList", editionDataTypesId, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
  GetEditionAnketDataEvaluationTypes(id:number): Promise<any> {

    return this.http.post("/api/Sheared/GetEditionAnketDataEvaluationTypes", id, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
  GetWeeksForSyllabus(weeks:number=17): Promise<any> {

    return this.http.post("/api/Sheared/GetWeeksForSyllabus", weeks, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetInformationBoardTypes(): Promise<any> {

    return this.http.post("/api/Sheared/GetInformationBoardTypes", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }


  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}

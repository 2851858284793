import { Component } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
// import { User } from '../identity/_models';
import { Caption, PortalLanguageCaption } from '../LanguageModel/Caption';
import { LanguageService } from '../Service/LanguageService';
import { AuthenticationService } from '../Identity/_services/authentication.service';
import { Router } from '@angular/router';
import { User } from '../Identity/_models/user';
import { ChangePassword } from '../Identity/ChangePassword/ChangePassword';
import { MatDialog } from '@angular/material/dialog';
import { CorrespondencStatement } from '../Models/Correspondence';
import { ShearedDataService } from '../Lecturer/Service/ShearedData';
import { CorrespondenceService } from '../Lecturer/Correspondence/Service/CorrespondenceService';


@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  public captions: Caption;
  public userName: string
  public profileImageFileStream: string;
  public showContent: boolean = false;
  subscription: Subscription;
  constructor(public dialog: MatDialog,
    private router: Router,
    public LanguageService: LanguageService,
    private shearedDataService: ShearedDataService,
    private correspondenceService: CorrespondenceService,
    private authenticationService: AuthenticationService ) {

    this.subscription = this.shearedDataService.data
      .subscribe(mode => {
        if (mode != null) {
          this.UserProfileLoad();
          this.showContent = true;
          this.LoadCorrespondenceData();
        }
        else {
          this.showContent = false;
        }
      });

  }


  correspondence: Array<CorrespondencStatement>;

  LoadCorrespondenceData() {
    this.correspondenceService.UnReadCorrespondence().then(e => {
      this.correspondence = e;

    });
  }


  isExpanded = false;

  collapse() {
    this.isExpanded = false;

  }
  ngAfterContentInit(): void {



  }
  BehavRet(image: string): BehaviorSubject<string> {
    return new BehaviorSubject(image);
  }
 
  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  //UserName() {
  // let ise= new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));

  //  return ise.getValue()!=null? ise.getValue().username:"";
  //}

  //ShowMenu(): any {

  //  if (this.router.url.startsWith('/login')) {
  //    this.showContent = false;
  //  }
  //  else {
  //    this.showContent = true;
  //    this.UserProfileLoad();
  //  }

  //}
  UserProfileLoad() {

    let ise = JSON.parse(localStorage.getItem('currentUser')) as User;
    if (ise != null && this.userName != ise.username) {
      this.userName = ise.username;
      this.profileImageFileStream = ise.lecturer.profileImageFileStream;
      if (localStorage.getItem("language") != null) {
        this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];
      }
    }

  }


  LogOut() {
    this.authenticationService.logout().then(e => {
      if (e) {
        //this.router.navigate(['/']);
        this.router.navigateByUrl('/login')
      }
    });
  }
  ChangePassword() {
    let dialogRef = this.dialog.open(ChangePassword, {
      panelClass: 'my-centered-dialog',
      height: '320px',
      width: '470px',
      disableClose: true,
      data: { formType: 1, recipientsList: null }
    });

    dialogRef.afterClosed().subscribe(result => {

      //this.animal = result;
    });
  }

}

import { Component, Inject,  ViewChild } from '@angular/core';
import { Router,  ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormBuilder } from '@angular/forms';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { PersoneListDialog } from '../../../Components/Dialogs/PersonListDialog/PersoneListDialog';;
import { CorrespondenceService } from '../Service/CorrespondenceService';
import { CorrespondenceDataWithAttachmentSend, CorrespondenceUsers, CorrespondencAttachment, CorrespondencStatement, CorrespondeceTypesData } from '../../../Models/Correspondence';
import { StudentListOnLectureForView } from '../../../Models/Graids';
import { FileUploadReturn } from '../../../Models/file';
import { SharedService } from '../../Service/SharedService';
import { Caption, PortalLanguageCaption } from '../../../LanguageModel/Caption';





@Component({
  selector: 'CreateViewLetterDocument',
  templateUrl: 'CreateViewLetterDocument.html',
  styleUrls: ['CreateViewLetterDocument.css']//,
  // providers: [ChecklistDatabase]
})



export class CreateViewLetterDocument  {

  correspondenceType: Array<CorrespondeceTypesData>;
  importantStatus: any;
  corState: any;
  selectedCoreType: CorrespondeceTypesData;
  selectedImportentStatus: any;
  selectedCorState: any;
  letterContent: any = "";
  answerletterContent: any = "";
  subjectText: any;
  correspondencStatementList: Array<CorrespondencStatement>;
  correspondencAttachments: Array<CorrespondencAttachment>;
  public captions: Caption;

  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateViewLetterDocument>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private activatedRoute: ActivatedRoute,
    //private SharedService: SharedService,
    private CorrespondenceService: CorrespondenceService,
    private SharedService: SharedService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router, private fb: FormBuilder) {

    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];

  }
 
  public Editor = DecoupledEditor;
  public answerEditor = DecoupledEditor;
  selection = new SelectionModel<StudentListOnLectureForView>(true, []);

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  displayedColumns: string[] = ['fName', 'sName', 'pn', 'facultyCaption', 'studyLevelCaption', 'semester'];
  dataSource = new MatTableDataSource<StudentListOnLectureForView>(this.selection.selected);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.correspondencAttachments = new Array<CorrespondencAttachment>();
    this.dataSource.paginator = this.paginator;
    if (this.data.formType == 1 || this.data.formType==2) {
      this.CorrespondenceService.GetCorrespondeceImportantStatus().then(e => { this.importantStatus = e; });
    }
    if (this.data.formType == 1) {
      this.CorrespondenceService.GetCorrespondeceTypes().then(e => { this.correspondenceType = e; });
      
    }
    else if (this.data.formType == 2 || this.data.formType == 3) {
      if (this.data.formType == 2) {
        this.CorrespondenceService.GetCorState().then(e => { this.corState = e; });
      }
      this.correspondencStatementList = this.data.recipientsList as Array<CorrespondencStatement>;
      if (this.correspondencStatementList.length > 1) {


      } else if (this.correspondencStatementList.length == 1) {
        this.CorrespondenceService.GetCorrespondence(this.correspondencStatementList[0].coresspondenceId).then(e => {
          if (this.data.formType == 2) {
            this.subjectText = "Re:" + e.correspondenceData.correspondenceSubject;
            this.letterContent = e.correspondenceData.correspondenceDescription;
            this.selectedCorState = e.correspondenceData.stateId;
            this.correspondencAttachments = e.correspondencAttachments;
          } else if (this.data.formType == 3) {
            this.subjectText = e.correspondenceData.correspondenceSubject;
            this.letterContent = e.correspondenceData.correspondenceDescription;
            this.selectedCorState = e.correspondenceData.stateId;
            this.correspondencAttachments = e.correspondencAttachments;
          }

        });
      }
      else {
        
      }
    }
  }

  AddRecipients(): void {
    let dialogRef = this.dialog.open(PersoneListDialog, {
      panelClass: 'my-centered-dialog',
      height: '80%',
      width: '80%',
      disableClose: true,
      data: null
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.selection = result;
      this.dataSource.data=this.selection.selected;
      
    });
  }

  allSend = true;
  SendLetter(): void {
    if (this.allSend) {
      this.allSend = false;
      if (this.data.formType == 1 || this.data.formType == 2) {
        let c = new CorrespondenceDataWithAttachmentSend();
        if (this.data.formType == 1) {
          c.correspondenceData = {
            correspondeceTypeId: this.selectedCoreType.id, importantId: this.selectedImportentStatus,
            stateId: 0, correspondenceSubject: this.subjectText,
            correspondenceDescription: this.letterContent, fromCourseId:null
          };
        } else if (this.data.formType == 2) {
          if (this.correspondencStatementList.length == 1) {
            c.correspondenceData = {
              correspondeceTypeId: this.correspondencStatementList[0].correspondeceTypeId, importantId: this.selectedImportentStatus,
              stateId: 0, correspondenceSubject: this.subjectText,
              correspondenceDescription: this.answerletterContent, fromCourseId: this.correspondencStatementList[0].fromCourseId
            };
          } else if (this.correspondencStatementList.length > 1) {
            c.correspondenceData = {
              correspondeceTypeId: this.correspondencStatementList[0].correspondeceTypeId, importantId: this.selectedImportentStatus,
              stateId: 0, correspondenceSubject: this.subjectText,
              correspondenceDescription: this.answerletterContent, fromCourseId: this.correspondencStatementList[0].fromCourseId
            };
          }
        }
        c.users = new Array<CorrespondenceUsers>();
        c.correspondencAttachments = this.correspondencAttachments;

        if (this.data.formType == 1 && this.selectedCoreType.receiveStudent) {
          for (let i = 0; i < this.selection.selected.length; i++) {
            c.users.push({ asStudentId: this.selection.selected[i].studentId, personeId: this.selection.selected[i].personID, asPersonalId: null, parentId: null });
          }
        }
        else if (this.data.formType == 2 && this.selectedCoreType.receiveStudent) {
          for (let i = 0; i < this.correspondencStatementList.length; i++) {
            c.users.push({ asStudentId: this.correspondencStatementList[i].asStudentId, personeId: this.correspondencStatementList[i].personId, asPersonalId: this.correspondencStatementList[i].asPersonalId, parentId: this.correspondencStatementList[i].coresspondenceId });
          }
        }
        this.CorrespondenceService.SendCorrespondence(c).then(e => {
          if (e.seccuss) { this.dialogRef.close(); alert(this.captions.messageSendSeccuss); } else {
            alert(e.resultText);
          }
        });
      }
    }
  }

  DownloadDocument(fileId: string) {
    this.SharedService.downloadFile(fileId);
  }

  FileUploaded(file: FileUploadReturn) {
    this.correspondencAttachments.push({ fileId: file.fileId, corespondenceId: null, fileName: file.fileName });
    //alert(fileId);
  }
  RemoveFile(f: CorrespondencAttachment) {
    var index = this.correspondencAttachments.indexOf(f);
    this.correspondencAttachments.splice(index, 1);
  }



}


import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, AfterViewInit, ViewChild, ViewEncapsulation, Output, EventEmitter, } from '@angular/core';
//import { SylabusListData } from '../../Models/SylabusesList';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
//import { SharedService } from '../SharedService/SharedService';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { FormControl } from '@angular/forms';
//import { EditRowCell } from '../../Models/FIltering';
//import { CourseService } from '../Syllabuses/Service/CourseService';
import { StudentsListData, RetStudList } from '../../Models/StudentsList';
//import { UIfilter } from '../../Models/filters';
import { merge, of as observableOf} from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { CorrespondenceService } from './Service/CorrespondenceService';
//import { SearchFilterSSCCConfig, SearchFilterType } from '../../Components/SearchFilters/SearchFilterSSCC/SearchFilterSSCC';
//import { CorrespondenceDirection, RetStatementList, CorrespondencStatement, PersonType } from '../../Models/Correspondence';
import { CreateViewLetterDocument } from './Dialogs/CreateViewLetterDocument';
import { SelectionModel } from '@angular/cdk/collections';
import { RetStatementList, CorrespondenceDirection, CorrespondencStatement, PersonType, CorrespondensCategoryGroup } from '../../Models/Correspondence';
import { UIfilter, FilterPersone, FilterSubjectSylabusCourseEduPrograms } from '../../Models/filters';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Caption, PortalLanguageCaption } from '../../LanguageModel/Caption';


@Component({
  selector: 'correspondenceMainForm',
  templateUrl: 'CorrespondenceMainForm.html',
  styleUrls: ['CorrespondenceMainForm.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CorrespondenceMainForm {
  public statementList: RetStatementList;
  resultsLength = 0;
  categoryGroup: any;
  isLoadingResults = false;
  isRateLimitReached = false;
  selectedRow: any;
  selectedGroup: number;
  activeNode: any;
  flt: CorrespondenceDirection;
  @ViewChild(MatPaginator, { static:  true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  _filter: UIfilter;
 // @Output() onStudent = new EventEmitter<StudentsListData>();
  selection = new SelectionModel<CorrespondencStatement>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.entitiesDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.entitiesDataSource.data.forEach(row => this.selection.select(row));
  }

  Filter(filter: UIfilter): void {
    this._filter = filter;
     //this.ShowStudents(filter);
  }
  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }
  displayedColumns: string[] = ['select','fName', 'sName', 'pn', 'facultyCaption', 'studyLevelCaption', 'directionCaption',
    'staffStatus', 'studentEducationStatus', 'staffPosition', 'stateCaption', 'importentStatusCaption', 'correspondeceTypeCaption'
    , 'correspondenceSubject', 'correspondenceDescription', 'syllabusCaption', 'readStatus', 'createDate'];
  public entitiesDataSource: MatTableDataSource<CorrespondencStatement> = new MatTableDataSource();

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  private _transformer = (node: boardType, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id,
      icon: node.icon,
      groupId: node.groupId,
      count: node.count,
      parentid: node.parentid
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  public captions: Caption;
  public pIt: Array<boardType> = new Array<boardType>();

  constructor(public dialog: MatDialog,
    //private SharedService: SharedService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router, private cd: ChangeDetectorRef,
    private CorrespondenceService: CorrespondenceService,) {
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];


    this.pIt[0] = new boardType();
    this.pIt[1] = new boardType();
    //pIt[2] = new boardType();
    this.pIt[0].id = 1;
    this.pIt[0].name = this.captions.incoming;
    this.pIt[0].icon = "room";
    //this.pIt[0].children = new Array<boardType>();
    this.pIt[1].id = 2;
    this.pIt[1].name = this.captions.outgoing;
    this.pIt[1].icon = "room";
    //this.pIt[1].children = new Array<boardType>();
    //pIt[2].id = 3;
    //pIt[2].name = "განათლება/აქტივობები";
    //pIt[2].icon = "room";
    //pIt[2].children = new Array<boardType>();

    this.dataSource.data = this.pIt;


  }
  
  ngOnInit() {
    merge(//this.sort.sortChange,
      this.paginator.page)
      .pipe(
        // startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;

          return this.CorrespondenceService.GetIncomingOutgonigCorrespondence({
            correspondenceDirection: this.flt,
            correspondenceStatusId: 1, programId: null, subProgramId:null, correspondenceTypeId: this.selectedGroup, courseId: null, directionId: null, importent: null, studyLevelId: null, studentStatusId: null, personStatus: PersonType.student,
            read: null, pn: null, faculty: null, fName: null, sName: null, index: this.paginator.pageIndex, take: this.paginator.pageSize
          });
        }),
        map(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          //alternatively to response headers;
          this.resultsLength = data.count;
          this.categoryGroup = data.categoryGroup;
          return data.statementData;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;

          return observableOf([]);
        })
      ).subscribe(data => { this.entitiesDataSource.data = data; });
  }

  step = 0;

  //setStep(index: number) {
  //  this.step = index;
  //  this.selectedGroup = null;
  //  if (index == 0) {
  //    this.ShowLetters(CorrespondenceDirection.inComing)
  //  }
  //  else if (index == 1) {
  //    this.ShowLetters(CorrespondenceDirection.outGoing)
  //  }


  //}

  SelectedGroup(group: ExampleFlatNode) {
    this.selectedGroup = group.groupId;

    if (group.parentid == 1) {
      this.ShowLetters(CorrespondenceDirection.inComing, group)
    }
    else if (group.parentid == 2) {
      this.ShowLetters(CorrespondenceDirection.outGoing, group)
    }


    this.activeNode = group;
  }

  FilterNews(node: ExampleFlatNode) {

    if (node.groupId == null) {
      if (node.id == 1) {
        this.ShowLetters(CorrespondenceDirection.inComing, node)
      }
      else if (node.id == 2) {
        this.ShowLetters(CorrespondenceDirection.outGoing, node)
      }
    }
    else {
      this.SelectedGroup(node);
    }
  }


  FillSubGroups(groups: Array<CorrespondensCategoryGroup>, node: ExampleFlatNode) {
    if (node.groupId == null) {
      if (node.id == 1) {
        this.pIt[0].children = new Array<boardType>()
        for (let i = 0; i < groups.length; i++) {
          this.pIt[1].children.push({ id: null, groupId: groups[i].correspondeceTypeId, name: groups[i].caption, count: groups[i].count, icon: null, parentid: 1, children: null });
        }
      }
      else if (node.id == 2) {
        this.pIt[1].children = new Array<boardType>()
        for (let i = 0; i < groups.length; i++) {
          this.pIt[1].children.push({ id: null, groupId: groups[i].correspondeceTypeId, name: groups[i].caption, count: groups[i].count, icon: null, parentid: 2, children: null });
        }
      }
      this.dataSource.data = this.pIt;

      // setInterval(e => { this.activeNode = node; }, 1000);

    }
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  
  //ShowLetters(flt: CorrespondenceDirection) {

  //  if (flt) {
  //    //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
  //    this.selection.clear();
  //    this.flt = flt;
  //    this.CorrespondenceService.GetIncomingOutgonigCorrespondence({
  //      correspondenceDirection: flt,
  //      correspondenceStatusId: 1, correspondenceTypeId: this.selectedGroup, courseId: null, directionId: null, importent: null, studyLevelId: null, studentStatusId: null, personStatus: PersonType.student,
  //        read: null, pn: null, faculty: null, fName: null, sName: null, index: 0, take: 20
  //    }).then(e => {
  //      this.entitiesDataSource.data = (e as RetStatementList).statementData; this.resultsLength = (e as RetStatementList).count,
  //        this.categoryGroup = (e as RetStatementList).categoryGroup});
  //  }
  //}


  ShowLetters(flt: CorrespondenceDirection, node: ExampleFlatNode) {

    if (flt) {
      //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      this.selection.clear();
      this.flt = flt;
      this.CorrespondenceService.GetIncomingOutgonigCorrespondence({
        correspondenceDirection: flt,
        correspondenceStatusId: 1, programId: null, subProgramId: null, correspondenceTypeId: this.selectedGroup, courseId: null, directionId: null, importent: null, studyLevelId: null, studentStatusId: null, personStatus: PersonType.student,
        read: null, pn: null, faculty: null, fName: null, sName: null, index: 0, take: 20
      }).then(e => {
        this.entitiesDataSource.data = (e as RetStatementList).statementData; this.resultsLength = (e as RetStatementList).count,
          this.categoryGroup = (e as RetStatementList).categoryGroup;
        this.FillSubGroups(this.categoryGroup, node);
      });

    }
  }


  
  StudentSelect(student: any, i: any) {
    this.selectedRow = i;
    //this.onStudent.emit(student);
  }

  CreateNewLetter(): void {
    let dialogRef = this.dialog.open(CreateViewLetterDocument, {
      panelClass: 'my-centered-dialog',
      height: '80%',
      width: '80%',
      disableClose: true,
      data: { formType: 1, recipientsList: null }
    });

    dialogRef.afterClosed().subscribe(result => {
     
      //this.animal = result;
    });

  }

  AnswerLetter(): void {
    if (this.selection.selected.length >0) {
      let dialogRef = this.dialog.open(CreateViewLetterDocument, {
        panelClass: 'my-centered-dialog',
        height: '80%',
        width: '80%',
        disableClose: true,
        data: { formType: 2, recipientsList: this.selection.selected }
      });

      dialogRef.afterClosed().subscribe(result => {
       
        //this.animal = result;
      });
    }
  }
  ViewLetter(): void {
    if (this.selection.selected.length == 1) {
      let dialogRef = this.dialog.open(CreateViewLetterDocument, {
        panelClass: 'my-centered-dialog',
        height: '80%',
        width: '80%',
        disableClose: true,
        data: { formType: 3, recipientsList: this.selection.selected }
      });

      dialogRef.afterClosed().subscribe(result => {
        
        //this.animal = result;
      });
    }
  }

}
export class boardType {
  id: number;
  name: string;
  parentid: number;
  icon: string;
  children?: boardType[];
  groupId?: number;
  count?: number;
}
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  icon: string;
  id: number;
  groupId?: number;
  count?: number;
  parentid: number;
}

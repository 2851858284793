<div class="_cs_flex _cs_flex-a--center _cs_color-bg-gradient--primary-3 _cs_position-absolute _cs_position-left-px--0 _cs_position-up-px--0 _cs_size-w-percent--100 _cs_p-top--sm _cs_p-down--sm _cs_p-left--md _cs_p-right--md">
  <span class="_cs_label _cs_label-lg _cs_label-font-setting-case-on _cs_label-500 _cs_color-primary-5">{{captions.resetPassword}}</span>
</div>

<mat-dialog-content>
  <div>
    <mat-form-field class="pass">
      <input type="text" placeholder={{captions.persNumber}} matInput [(ngModel)]="recoverPassword.persNumber" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="pass">
      <input type="text" placeholder={{captions.email}} matInput [(ngModel)]="recoverPassword.email" />
    </mat-form-field>
  </div>
  <div *ngIf="!shorCodeFiled">
    <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_m-left--md" (click)="ResetCodeToEmail()" >
      <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.sendCode}}</span>
    </button>
  </div>

  <div *ngIf="shorCodeFiled">
    <mat-form-field class="pass">
      <input type="text" placeholder={{captions.confirmationCode}} matInput [(ngModel)]="recoverPassword.onTimeCode" />
    </mat-form-field>
  </div>
  <div *ngIf="shorCodeFiled">
    <mat-form-field class="pass">
      <input type="password" placeholder={{captions.newPassword}} matInput [(ngModel)]="recoverPassword.newPassword" />
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="_cs_col-xs-12 _cs_p-left--sm _cs_p-right--sm">
  <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_m-left--md" (click)="ChangePassword()">
    <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.change}}</span>
  </button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_m-left--md" [mat-dialog-close]="true">
    <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.close}}</span>
  </button>
</mat-dialog-actions>

<div
  class="_cs_flex _cs_flex-a--center _cs_color-bg-gradient--primary-3 _cs_position-absolute _cs_position-left-px--0 _cs_position-up-px--0 _cs_size-w-percent--100 _cs_p-top--sm _cs_p-down--sm _cs_p-left--md _cs_p-right--md">
  <span class="_cs_label _cs_label-lg _cs_label-font-setting-case-on _cs_label-500 _cs_color-primary-5">{{captions.addressees}}</span>
  <button
    class="_cs_m-left--auto _cs_icon _cs_icon-lg _cs_border-radius--full _cs_shadow-primary-0-0--3 _cs_color-bg--primary-5"
    [mat-dialog-close]="true">
    <span class="_cs_icon _cs_icon-xs _cs_icon-cross _cs_color-primary-2"></span>
  </button>
</div>
<mat-dialog-content class="_cs_size-w-percent--100 _cs_p-left--lg _cs_p-right--lg">

  <SearchStudGroup (onFilter)="Filter($event)">

  </SearchStudGroup>
  <div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      GitHub's API rate limit has been reached. It will be reset in one minute.
    </div>
  </div>
  <table cdk-table [dataSource]="entitiesDataSource" class="table table-bordered table-hover _cs_table-first-checkbox">
    <ng-container cdkColumnDef="select">
      <th cdk-header-cell *cdkHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <!-- Position Column -->
    <ng-container cdkColumnDef="fName">
      <th cdk-header-cell *cdkHeaderCellDef>{{captions.fName}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.fName}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container cdkColumnDef="sName">
      <th cdk-header-cell *cdkHeaderCellDef>{{captions.sName}} </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.sName}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container cdkColumnDef="pn">
      <th cdk-header-cell *cdkHeaderCellDef>{{captions.persNumber}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.pn}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container cdkColumnDef="facultyCaption">
      <th cdk-header-cell *cdkHeaderCellDef>{{captions.faculty}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.facultyCaption}} </td>
    </ng-container>
    <ng-container cdkColumnDef="studyLevelCaption">
      <th cdk-header-cell *cdkHeaderCellDef>{{captions.studyLevel}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.studyLevelCaption}} </td>
    </ng-container>
    <ng-container cdkColumnDef="semester">
      <th cdk-header-cell *cdkHeaderCellDef>{{captions.semester}}</th>
      <td cdk-cell *cdkCellDef="let element"> {{element.semester}} </td>
    </ng-container>

    <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;  let i = index" (dblclick)="StudentSelect(row,i)"
      [ngClass]="{'highlight': selectedRow == i}"></tr>
  </table>
  <!--<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons #paginator [length]="resultsLength"></mat-paginator>-->
  <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons #paginator [length]="resultsLength">
  </mat-paginator>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="RecipientsData()" class="_cs_button _cs_button-sm _cs_button-border--positive _cs_m-left--md">
    <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.add2}}</span>
  </button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button [mat-dialog-close]="true" class="_cs_button _cs_button-sm _cs_button-border--positive _cs_m-left--md">
    <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.cancel}}</span>
  </button>
</mat-dialog-actions>

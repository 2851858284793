export class FilterSubjectSylabusCourseEduPrograms {
  public facultyId: number;
  public studyLevelId: number;
  public studyYearId: number;
  public studySemesterId: number;
  public directionId: number;
  public languageId: number;
  public caption: string;
  public credit: number;
  public programType: number;
  public syllabusType: number;
}

export class FilterPersone {
  public facultyId: number;
  public studyLevelId: number;
  public directionId: number;
  public enterFromYear: number;
  public enterToYear: number;
  public persNumber: string;
  public firstName: string;
  public lastName: string;
  public birthFromYear: number
  public birthToYear: number;
  public complateFromYear: number;
  public complateToYear: number;
  public semesterFrom: number;
  public semesterTo: number;
  public eduStatus: number[];
  public finStatus: number[];
  public workerStatus: number[];
  public avgMinReiting: number;
  public avgMaxReiting: number;
  public sex: number;
  public personType: number;

}

export class UIfilter {
 public FilterSubjectSylabusCourseEduPrograms: FilterSubjectSylabusCourseEduPrograms;
 public FilterPersone: FilterPersone;
}

export class StudyGroupFilter {
  public facultyId: number;
  public studyLevelId: number;
  public studyYearId: number;
  public studySemesterId: number;
  public directionId: number;
  public languageId: number;
  public syllabusType: number;
  public groupId: number;
  public courseId: number;
  public lectureTypeId: number;
}

export class OrderFiltering {
  public orderNumber: string;
  public orderDateFrom: Date;
  public orderDateTo: Date;
  public orderCategoryId: number;
  public orderForId: number;
  public orderContent: string;
  public index: number;
  public take: number;
}


export class FilterByStudyElements {
  public facultyId: number;
  public studyLevelId: number;
  public studyProgarmId: number;
  public directionId: number;
}

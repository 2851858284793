<div class="_cs_flex _cs_size-w-percent--100">
  <div class="_cs_size-w-percent--100 _cs_p-left--lg _cs_p-right--lg _cs_p-down--lg">
    <div class="_cs_size-h-percent--100 sidebarbg _cs_flex">

      <div class="sidebarbg__wrapper _cs_p-left--none-i _cs_size-w-percent--100 _cs_flex _cs_flex-d--column">

        <div class="_cs_m-down--md _cs_m-top--xxl">
          <div
            class="_cs_p-xs _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_border-down--primary-6 _cs_flex _cs_flex-a--center">
            <span
              class="_cs_icon _cs_icon-xl _cs_shadow-primary-6-0--2 _cs_border-radius--full _cs_border--primary-6 _cs_m-right--md">
              <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-news"></span>

            </span>
            <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold
                                     _cs_color-primary-6">{{captions.markJounal}}</span>
          </div>
        </div>
        <div class="_cs_size-w-percent--100">

          <SearchStudGroup (onFilter)="Filter($event)">

          </SearchStudGroup>
          
        </div>




        <div *ngIf="studentEvaluationGenerator">


          <mat-tab-group>
            <mat-tab label={{captions.scores}}>
              <div class="_cs_col-xs-12 _cs_scrolled-table ">

                <div class="btn-group btn-group-sm _cs_color-bg--primary-5 _cs_size-w-percent--100  _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_flex _cs_flex-wrap _cs_position-relative _cs_p-sm _cs_p-down--none"
                     [attr.colspan]="studentEvaluationGenerator.evaluationHeader.length+5">
                  <button (click)="SaveMarks()"
                          class="_cs_button _cs_button-sm _cs_button-border--positive _cs_border-radius--sm _cs_m-down--sm _cs_m-left--xs _cs_m-right--xs ">
                    <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-500 _cs_label-t-u _cs_color-primary-6">
                      {{captions.save}}
                    </span>
                  </button>
                  <button (click)="printJournal('PDF',1)"
                          class="_cs_button _cs_button-sm _cs_button-border--positive _cs_border-radius--sm _cs_m-down--sm _cs_m-left--xs _cs_m-right--xs ">
                    <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-500 _cs_label-t-u _cs_color-primary-6">
                      Export PDF
                    </span>
                  </button>
                  <button (click)="printJournal('EXCEL',1)"
                          class="_cs_button _cs_button-sm _cs_button-border--positive _cs_border-radius--sm _cs_m-down--sm _cs_m-left--xs _cs_m-right--xs ">
                    <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-500 _cs_label-t-u _cs_color-primary-6">
                      Export EXCEL
                    </span>
                  </button>
                  <button (click)="printJournal('WORD',1)"
                          class="_cs_button _cs_button-sm _cs_button-border--positive _cs_border-radius--sm _cs_m-down--sm _cs_m-left--xs _cs_m-right--xs ">
                    <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-500 _cs_label-t-u _cs_color-primary-6">
                      Export WORD
                    </span>
                  </button>
                  
                </div>
                <div class="row">
                  <div class="col-12">
                    <div style="padding-left:20px;">
                      <div [style.width]="(innerWidth-100)+'px'">
                        <div>
                          <div style="float:left;">
                            <table class="special-talbe table-hover table-bordered table-sm" style="padding: 0; margin: 0;">
                              <thead>
                                <tr style="height:50px" *ngIf="studentEvaluationGenerator.evaluationHeader.length>0&&studentEvaluationGenerator.evaluationHeader[0].weekId&&studentEvaluationGenerator.studentListOnLecture&&studentEvaluationGenerator.studentListOnLecture.length>0&&studentEvaluationGenerator.studentListOnLecture[0].eM!=null&&studentEvaluationGenerator.studentListOnLecture[0].eM.length>0">
                                  <th colspan="6"></th>
                                </tr>
                                <tr style="height:150px" class="special-talbe__whiteitem" *ngIf="studentEvaluationGenerator.studentListOnLecture&&studentEvaluationGenerator.studentListOnLecture.length>0&&studentEvaluationGenerator.studentListOnLecture[0].eM!=null&&studentEvaluationGenerator.studentListOnLecture[0].eM.length>0"><th colspan="6"></th></tr>
                                <tr style="height:150px" class="special-talbe__whiteitem" *ngIf="studentEvaluationGenerator.evaluationHeader.length>0&&studentEvaluationGenerator.evaluationHeader[0].weekDayId&&studentEvaluationGenerator.studentListOnLecture&&studentEvaluationGenerator.studentListOnLecture.length>0&&studentEvaluationGenerator.studentListOnLecture[0].eM!=null&&studentEvaluationGenerator.studentListOnLecture[0].eM.length>0"><th colspan="6"></th></tr>

                                <tr style="vertical-align:bottom;" [style.height]="timeHeight+'px'">
                                  <th style="width:25px;max-width:25px">#</th>
                                  <th style="width:120px;max-width:120px">{{captions.sName}}</th>
                                  <th style="width:120px;max-width:120px">{{captions.fName}}</th>
                                  <th style="width:130px;max-width:130px">{{captions.persNumber}}</th>
                                  <th style="width:180px;max-width:180px">{{captions.studyProgram}}</th>
                                  <th style="width:55px;max-width:55px">{{captions.sumScore}}</th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                          <div *ngIf="studentEvaluationGenerator.studentListOnLecture&&studentEvaluationGenerator.studentListOnLecture.length>0&&studentEvaluationGenerator.studentListOnLecture[0].eM!=null&&studentEvaluationGenerator.studentListOnLecture[0].eM.length>0" [style.width]="(innerWidth-100-660)+'px'" style="float:left; overflow: scroll; overflow: hidden; padding:0; margin:0; " id="table-scroll-employees">
                            <table class="special-talbe table-hover table-bordered table-sm" style="padding: 0; margin: 0;">
                              <thead>
                                <tr style="height:50px;vertical-align:bottom;" *ngIf="studentEvaluationGenerator.evaluationHeader.length>0&&studentEvaluationGenerator.evaluationHeader[0].weekId">

                                  <ng-container *ngFor="let hd of studentEvaluationGenerator.evaluationHeader; let is=index;">
                                    <ng-container *ngIf="hd.weekId!=(studentEvaluationGenerator.evaluationHeader.length>is+1? studentEvaluationGenerator.evaluationHeader[is+1].weekId:-1)">
                                      <th style="min-width:40px;max-width:40px;" [attr.colspan]="WeekIdentyCount(hd.weekId)" class="cellTextOrCon">
                                        {{hd.weekNameGEO}}
                                      </th>
                                    </ng-container>
                                  </ng-container>

                                </tr>
                                <tr style="height:150px;vertical-align:bottom;" class="special-talbe__whiteitem">

                                  <ng-container *ngFor="let hd2 of studentEvaluationGenerator.evaluationHeader;let ic=index;">
                                    <ng-container *ngIf="hd2.componentOnSyllabusId!=(studentEvaluationGenerator.evaluationHeader.length>ic+1? studentEvaluationGenerator.evaluationHeader[ic+1].componentOnSyllabusId:-1)||(hd2.componentOnSyllabusId==studentEvaluationGenerator.evaluationHeader[ic+1].componentOnSyllabusId&&hd2.weekId!=studentEvaluationGenerator.evaluationHeader[ic+1].weekId)">
                                      <th style="min-width:40px;max-width:40px;" [attr.colspan]="ComponentIdentyCount(hd2.componentOnSyllabusId,hd2.weekId)" class="cellTextOrCon">
                                        <span class="tdtextOrientation component"> {{hd2.componentName.substring(0,16)}}</span>
                                      </th>
                                    </ng-container>
                                  </ng-container>
                                </tr>
                                <tr class="special-talbe__whiteitem" style="height:150px;vertical-align:bottom;" *ngIf="studentEvaluationGenerator.evaluationHeader.length>0&&studentEvaluationGenerator.evaluationHeader[0].weekDayId">
                                  <ng-container *ngFor="let hd3 of studentEvaluationGenerator.evaluationHeader;let iwd=index;">
                                    <ng-container *ngIf="hd3.weekDayId!=(studentEvaluationGenerator.evaluationHeader.length>iwd+1?
                                        studentEvaluationGenerator.evaluationHeader[iwd+1].weekDayId:-1)||
                                        (hd3.weekDayId==studentEvaluationGenerator.evaluationHeader[iwd+1].weekDayId&&
                                        (hd3.componentOnSyllabusId!=studentEvaluationGenerator.evaluationHeader[iwd+1].componentOnSyllabusId||
                                        hd3.weekId!=studentEvaluationGenerator.evaluationHeader[iwd+1].weekId))">
                                      <th style="min-width:40px;max-width:40px;" [attr.colspan]="weekDayIdIdentyCount(hd3.weekDayId,hd3.weekId,hd3.componentOnSyllabusId)" class="cellTextOrCon">
                                        <span class="tdtextOrientation"> {{hd3.dayName}}</span>
                                      </th>
                                    </ng-container>
                                  </ng-container>
                                </tr>


                                <tr style="vertical-align:bottom;max-height:50px" [style.height]="timeHeight+'px'">
                                  <ng-container *ngFor="let hd3 of studentEvaluationGenerator.evaluationHeader; ">

                                    <th [ngClass]="{'cellTextOrCon':true,'datetimeC':hd3.startDateTime!=null}" style="min-width:40px;max-width:40px; max-height:50px">
                                      <div width="38">
                                        <span class="tdtextOrientation"> {{hd3.startTime==null?(hd3.startDateTime| date:'yyyy-MM-dd HH:mm'): hd3.startTime.substring(0, 5)}} &nbsp;</span>
                                      </div>
                                    </th>
                                  </ng-container>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div style="height:300px; overflow-y: auto;" [style.width]="(innerWidth-100)+'px'">
                        <div>
                          <div style="float:left">
                            <table class="special-talbe table-hover table-bordered table-sm" style="padding: 0; margin: 0;">
                              <tbody>
                                <tr style=" height:32px;min-height:32px;max-height:32px;" *ngFor="let m of studentEvaluationGenerator.studentListOnLecture | slice: lowValue : highValue;let i=index;">
                                  <td style="width:25px;max-width:25px">{{(i+1)+((pageIndex)*pageSize)}}</td>
                                  <td style="width:120px;max-width:120px;max-height:32px;">
                                    <div class="divRowElementHeight">
                                      {{m.sN}}
                                    </div>
                                  </td>
                                  <td style="width:120px;max-width:120px;max-height:32px;"><div class="divRowElementHeight">{{m.fN}}</div> </td>
                                  <td style="width:130px;max-width:130px;max-height:32px;">{{m.pn}}</td>
                                  <td style="width:180px;max-width:180px;max-height:32px;"> <div class="divRowElementHeight">{{m.pr}}</div> </td>
                                  <td style="width:55px;max-width:55px">
                                    {{m.sumMarks}}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div *ngIf="studentEvaluationGenerator.studentListOnLecture&&studentEvaluationGenerator.studentListOnLecture.length>0&&studentEvaluationGenerator.studentListOnLecture[0].eM!=null&&studentEvaluationGenerator.studentListOnLecture[0].eM.length>0" [style.width]="(innerWidth-100-660)+'px'" style="float:left; overflow-x: auto" id="table-scroll-tasks" (scroll)="scrollPos();">
                            <table class="special-talbe table-hover table-bordered table-sm" style="padding: 0; margin: 0;">
                              <tbody>
                                <tr style=" height:32px;min-height:32px;max-height:32px;" *ngFor="let m of studentEvaluationGenerator.studentListOnLecture | slice: lowValue : highValue;let i=index;">
                                  <td style="min-width:40px;max-width:40px;" *ngFor="let mark of m.eM; let im=index; trackBy:trackByFn" class="td">
                                    <div width="34" class="cellEW" *ngIf="!(editCell.rowId==(i+1)&&editCell.cellId==(im+1) && editCell.cellValue=='mark')" (click)="toggleCaption({rowId:(i+1),cellId:(im+1),cellValue:'mark'},mark)">{{studentEvaluationGenerator.evaluationHeader[im].evalutionTypeId==2?(mark==1?'+':''):mark}} </div>
                                    <input style="max-height:28px; max-width:35px" [attr.max]="studentEvaluationGenerator.evaluationHeader[im].maxEnterMark" min="0" type="number" [(ngModel)]="m.eM[im]" (ngModelChange)="changeValues(m.eM[im],studentEvaluationGenerator.evaluationHeader[im],m);SumStudentsGraids(m)" class="compoStyle" *ngIf="studentEvaluationGenerator.evaluationHeader[im].evalutionTypeId==1&&editCell.rowId==(i+1)&&editCell.cellId==(im+1) && editCell.cellValue=='mark'" [disabled]="(studentEvaluationGenerator.evaluationHeader[im].precondition>(m.smM!=null?m.smM:0))"/>
                                    <mat-checkbox style="max-height:28px; max-width:35px" [checked]="mark === 1" (change)="(m.eM[im] = ($event.checked ? 1 : 0));changeValues(m.eM[im],studentEvaluationGenerator.evaluationHeader[im],m);SumStudentsGraids(m)" *ngIf="studentEvaluationGenerator.evaluationHeader[im].evalutionTypeId==2&&editCell.rowId==(i+1)&&editCell.cellId==(im+1) && editCell.cellValue=='mark'"></mat-checkbox>
                                    <mat-select style="max-height:28px; max-width:35px" class="compoStyle" [(ngModel)]="m.eM[im]" (selectionChange)="changeValues(m.eM[im],studentEvaluationGenerator.evaluationHeader[im],m);SumStudentsGraids(m)" *ngIf="studentEvaluationGenerator.evaluationHeader[im].evalutionTypeId==3&&editCell.rowId==(i+1)&&editCell.cellId==(im+1) && editCell.cellValue=='mark'">
                                      <mat-option [value]="null"></mat-option>
                                      <mat-option *ngFor="let loop3 of counter(studentEvaluationGenerator.evaluationHeader[im].maxRanking); let ik= index" [value]="loop3">
                                        {{ik+1}}
                                      </mat-option>
                                    </mat-select>
                                  </td>
                                </tr>
                              </tbody>

                            </table>

                          </div>
                        </div>
                      </div>
                      <div>
                        <mat-paginator [length]="studentEvaluationGenerator.studentListOnLecture .length" [pageSize]="pageSize"
                                       (page)="pageEvent = getPaginatorData($event)">
                        </mat-paginator>
                      </div>
                    </div>
                  </div>
          
                </div>
         
              </div>
            </mat-tab>
            <mat-tab label={{captions.evaluationComponents}}>
              <div class="_cs_size-w-percent--100">

                <table class="table table-bordered" *ngIf="studentEvaluationGenerator">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{{captions.component}}</th>
                      <th>{{captions.calculationType}}</th>
                      <!--<th>შეფ. შეტ.</th>-->
                      <th>{{captions.gradeSimbole}}</th>
                      <th>{{captions.maxEnterScore}}</th>
                      <th>{{captions.componentQuantity}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let s of syllabusComponents; let iih=index">
                      <td>{{iih}}</td>
                      <td>{{s.componentName}}</td>
                      <td>{{s.calculationCaption}}</td>
                      <!--<td>{{s.evalutionTypeCaption}}</td>-->
                      <td>{{s.maxMark}}</td>
                      <td>{{s.maxEnterMark}}</td>
                      <td>{{s.maxCompCountOnWeeks}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </mat-tab>
            <mat-tab label={{captions.distributionOfComponents}}>
              <div class="_cs_col-xs-12 _cs_scrolled-table ">

                <div class="_cs_color-bg--primary-5 _cs_size-w-percent--100  _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_flex _cs_flex-wrap _cs_position-relative _cs_p-sm _cs_p-down--none">

                  <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_border-radius--sm _cs_m-down--sm _cs_m-left--xs _cs_m-right--xs " (click)="addCRow('addWeekComponent')">
                    <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-500 _cs_label-t-u _cs_color-primary-6">
                      დამატება
                    </span>

                  </button>

                  <button (click)="SaveSyllabus()"
                          class="_cs_button _cs_button-sm _cs_button-border--positive _cs_border-radius--sm _cs_m-down--sm _cs_m-left--xs _cs_m-right--xs ">
                    <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-500 _cs_label-t-u _cs_color-primary-6">
                      {{captions.save}}
                    </span>
                  </button>

                </div>
                <table class='table table-bordered table-hover table-sm' *ngIf="syllabusData">
                  <thead>

                    <tr>
                      <th>#</th>
                      <th> {{captions.week}}</th>
                      <th> {{captions.day}}</th>
                      <th> {{captions.component}}</th>
                      <th> {{captions.quantity}}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let c of syllabusData.syllabusComponentOnWeeks; let i=index;">
                      <tr *ngIf="c.operationStatus!=2">
                        <td>{{i+1}}</td>
                        <td (click)="toggleCaption({rowId:(i+1),cellValue:'weekName'},c)">
                          <mat-select [(ngModel)]="c.weekId" *ngIf="editCell.rowId==(i+1) && editCell.cellValue=='weekName'">
                            <mat-option *ngFor="let s of weeks; let i=index;" [value]="s.id" (click)="c.weekName=s.caption;">{{s.caption}}</mat-option>
                          </mat-select>
                          <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='weekName')">
                            {{c.weekName}} &nbsp;
                          </div>
                        </td>
                        <td (click)="toggleCaption({rowId:(i+1),cellValue:'dayId'},c)">
                          <mat-form-field *ngIf="editCell.rowId==(i+1) && editCell.cellValue=='dayId'" class="compInputStyle">
                            <input matInput [(ngModel)]="c.dayId" type="number" min="0" max="syllabusData.syllabus.duration">
                          </mat-form-field>
                          <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='dayId')">
                            {{c.dayId}} &nbsp;
                          </div>
                        </td>
                        <td (click)="toggleCaption({rowId:(i+1),cellValue:'addWeekComponent'},c)">
                          <mat-select [(ngModel)]="c.componentOnSyllabusId" *ngIf="editCell.rowId==(i+1) && editCell.cellValue=='addWeekComponent'">
                            <mat-option *ngFor="let s of syllabusData.studyComponents; let i=index;" [value]="s.id" (click)="c.componentName=s.componentTypeName;">{{s.componentTypeName}}</mat-option>
                          </mat-select>
                          <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='addWeekComponent')">
                            {{c.componentName}} &nbsp;
                          </div>
                        </td>
                        <td (click)="toggleCaption({rowId:(i+1),cellValue:'quantityWeek'},c)">
                          <mat-form-field *ngIf="editCell.rowId==(i+1) && editCell.cellValue=='quantityWeek'" class="compInputStyle">
                            <input matInput [(ngModel)]="c.quantity" type="number" min="1" (change)="ComponentQuantity(c)">
                          </mat-form-field>
                          <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='quantityWeek')">
                            {{c.quantity}} &nbsp;
                          </div>
                        </td>
                        <td><button ejs-button cssClass="e-outline" (click)="RemoveCRaw('addWeekComponent',c)">{{captions.delete}}</button></td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </mat-tab>
            <mat-tab label={{captions.subjectSyllabus}}>
              <div class="_cs_col-xs-12 _cs_scrolled-table ">
                <table class='table table-sm table-bordered'>
                  <thead>

                    <tr>
                      <th>#</th>
                      <th>{{captions.documentName}}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ll of syllabusData.syllabusFiles; let i=index" class='clickable-row'>
                      <td>{{i+1}}</td>
                      <td>
                        <button class="link" (click)="DownloadDocument(ll.fileId)">{{ll.fileName}}</button>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
             </mat-tab>
          </mat-tab-group>

         
        </div>


      </div>

    </div>
  </div>
</div>

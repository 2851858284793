<div class="example-loading-shade"
     *ngIf="isLoadingResults || isRateLimitReached">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
    GitHub's API rate limit has been reached. It will be reset in one minute.
  </div>
</div>
<table cdk-table [dataSource]="entitiesDataSource" class="table table-bordered table-hover">

  <!-- Position Column -->
  <ng-container cdkColumnDef="fName">
    <th cdk-header-cell *cdkHeaderCellDef> სახელი </th>
    <td cdk-cell *cdkCellDef="let element"> {{element.fName}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container cdkColumnDef="sName">
    <th cdk-header-cell *cdkHeaderCellDef> გვარი </th>
    <td cdk-cell *cdkCellDef="let element"> {{element.sName}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container cdkColumnDef="persNumber">
    <th cdk-header-cell *cdkHeaderCellDef> პირადი # </th>
    <td cdk-cell *cdkCellDef="let element"> {{element.persNumber}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container cdkColumnDef="fakulty">
    <th cdk-header-cell *cdkHeaderCellDef> ფაკულტეტი </th>
    <td cdk-cell *cdkCellDef="let element"> {{element.fakulty}} </td>
  </ng-container>

  <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
  <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;  let i = index" (dblclick)="StudentSelect(row,i)" [ngClass]="{'highlight': selectedRow == i}"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons #paginator [length]="resultsLength"></mat-paginator>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Infofilter, InformationBoardResult, InformationBoardDataHeader } from '../../Models/InformationBoardModel';




@Injectable()
export class InformationBoardService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private headers2 = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
  private heroesUrl = '/';
  constructor(private http: HttpClient) { }


  GetInfoList(filter: Infofilter): Promise<InformationBoardResult> {

    return this.http.post("/api/InformationBoard/GetInfoList", filter, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetInfoDataUserView(id: number): Promise<InformationBoardDataHeader> {

    return this.http.post("/api/InformationBoard/GetInfoDataUserView", id, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

}

<div
  class="_cs_flex _cs_flex-a--center _cs_color-bg-gradient--primary-3 _cs_position-absolute _cs_position-left-px--0 _cs_position-up-px--0 _cs_size-w-percent--100 _cs_p-top--sm _cs_p-down--sm _cs_p-left--md _cs_p-right--md">
  <span class="_cs_label _cs_label-lg _cs_label-font-setting-case-on _cs_label-500 _cs_color-primary-5"
        *ngIf="data.formType==1">{{captions.createMessage}}</span>
  <span class="_cs_label _cs_label-lg _cs_label-font-setting-case-on _cs_label-500 _cs_color-primary-5"
        *ngIf="data.formType==2">{{captions.answer}}</span>
  <span class="cs_label _cs_label-lg _cs_label-font-setting-case-on _cs_label-500 _cs_color-primary-5"
        *ngIf="data.formType==3&&(correspondencStatementList!==null&&correspondencStatementList.length==1)">
    {{captions.readMessageAuthor}}:{{correspondencStatementList[0].sName+' '+correspondencStatementList[0].fName}})
  </span>
  <button
    class="_cs_m-left--auto _cs_icon _cs_icon-lg _cs_border-radius--full _cs_shadow-primary-0-0--3 _cs_color-bg--primary-5"
    [mat-dialog-close]="true">
    <span class="_cs_icon _cs_icon-xs _cs_icon-cross _cs_color-primary-2"></span>
  </button>
</div>

<mat-dialog-content>

  <div class="_cs_flex _cs_flex-d--column">
    <div id="LetterType" class="_cs_size-w-percent--100 _cs_flex _cs_flex-wrap">
      <div class="_cs_col-xs-12 _cs_col-sm-6 _cs_p-left--sm _cs_p-right--sm" *ngIf="data.formType==1">
        <mat-form-field
          class="_cs_size-w-percent--100 _cs_p-down--none">
          <mat-label>{{captions.messageType}}</mat-label>
          <mat-select [(value)]="selectedCoreType">
            <mat-option *ngFor="let c of correspondenceType" [value]="c">{{c.caption}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="_cs_col-xs-12 _cs_col-sm-6 _cs_p-left--sm _cs_p-right--sm">
        <mat-form-field
          class="_cs_size-w-percent--100 _cs_p-down--none"
          *ngIf="data.formType==1||data.formType==2">
          <mat-label>{{captions.importanceLevel}}</mat-label>
          <mat-select [(value)]="selectedImportentStatus">
            <mat-option *ngFor="let c of importantStatus" [value]="c.id">{{c.caption}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div id="ShesStatusi" *ngIf="data.formType==2" class="_cs_size-w-percent--100 _cs_flex _cs_flex-wrap">
        <div class="_cs_col-xs-12 _cs_p-left--sm _cs_p-right--sm">
          <mat-form-field
            class="_cs_size-w-percent--100 _cs_p-down--none">
            <mat-label>{{captions.status}}</mat-label>
            <mat-select [(value)]="selectedCorState">
              <mat-option *ngFor="let c of corState" [value]="c.id">{{c.caption}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

    </div>
    <div id="subject">
      <div class="_cs_size-w-percent--100 _cs_p-left--sm _cs_p-right--sm">
        <mat-form-field
          class="_cs_size-w-percent--100 _cs_p-down--none"
          *ngIf="data.formType==1||data.formType==2">
          <input matInput placeholder={{captions.title}} [(ngModel)]="subjectText">
        </mat-form-field>
      </div>
    </div>
  </div>
  <div *ngIf="data.formType==3&&correspondencStatementList!=null">
    <div class="_cs_m-down--md">
      <span class="_cs_label _cs_label-md _cs_label-font-setting-case-on _cs_color-primary-1">
        <b>{{captions.messageType}}:</b> {{correspondencStatementList[0].correspondeceTypeCaption}} <br />
        <b>{{captions.importanceLevel}}:</b> {{correspondencStatementList[0].importentStatusCaption}} <br />
        <b>{{captions.status}}:</b> {{correspondencStatementList[0].stateCaption}} <br />
      </span>
    </div>
  </div>
  <div>

    <div id="adresats" *ngIf="data.formType==1&&selectedCoreType&&selectedCoreType.receiveStudent" class="_cs_flex _cs_flex-d--column">
      <div
        class="_cs_flex _cs_flex-a--center _cs_shadow-sm _cs_border-radius--sm _cs_color-bg--primary-5 _cs_p-lg _cs_m-down--lg">
       
        <button (click)="AddRecipients()" class="_cs_button _cs_button-sm _cs_button-border--positive _cs_m-left--md">
          <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.addStudents}}</span>
        </button>
      </div>
      <table cdk-table [dataSource]="dataSource" class="table table-hover table-bordered">
        <!-- Position Column -->
        <ng-container cdkColumnDef="fName">
          <th cdk-header-cell *cdkHeaderCellDef>{{captions.fName}} </th>
          <td cdk-cell *cdkCellDef="let element"> {{element.fName}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container cdkColumnDef="sName">
          <th cdk-header-cell *cdkHeaderCellDef>{{captions.sName}} </th>
          <td cdk-cell *cdkCellDef="let element"> {{element.sName}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container cdkColumnDef="pn">
          <th cdk-header-cell *cdkHeaderCellDef>{{captions.persNumber}} </th>
          <td cdk-cell *cdkCellDef="let element"> {{element.pn}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container cdkColumnDef="facultyCaption">
          <th cdk-header-cell *cdkHeaderCellDef>{{captions.faculty}}</th>
          <td cdk-cell *cdkCellDef="let element"> {{element.facultyCaption}} </td>
        </ng-container>

        <ng-container cdkColumnDef="studyLevelCaption">
          <th cdk-header-cell *cdkHeaderCellDef>{{captions.studyLevel}}</th>
          <td cdk-cell *cdkCellDef="let element"> {{element.studyLevelCaption}} </td>
        </ng-container>

        <ng-container cdkColumnDef="semester">
          <th cdk-header-cell *cdkHeaderCellDef> {{captions.semester}} </th>
          <td cdk-cell *cdkCellDef="let element"> {{element.semester}} </td>
        </ng-container>

        <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
        <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
    </div>

    <div id="description" class="_cs_size-w-percent--100 _cs_p-left--sm _cs_p-right--sm">
      <div *ngIf="correspondencStatementList==null||this.correspondencStatementList.length==1">
        <span class="_cs_label _cs_label-md _cs_label-font-setting-case-on _cs_color-primary-2 _cs_m-down--md">
          {{captions.content}}:
        </span>
        <div class="_cs_col-xs-12" style="height:200px">
          <ckeditor [editor]="Editor" [(ngModel)]="letterContent" (ready)="onReady($event)"></ckeditor>
        </div>
      </div>
      <div *ngIf="data.formType==2">
        <div class="_cs_col-xs-12" style="height:200px">
          <span class="_cs_label _cs_label-md _cs_label-font-setting-case-on _cs_color-primary-2 _cs_m-down--md">{{captions.answer2}}:</span>
          <ckeditor [editor]="answerEditor" [(ngModel)]="answerletterContent" (ready)="onReady($event)"></ckeditor>
        </div>
      </div>
    </div>
    <div id="documents" class="_cs_size-w-percent--100 _cs_p-left--sm _cs_p-right--sm">
      <div>
        {{captions.documents}}:
      </div>
      <div *ngIf="data.formType!=3">
        <FileUpload (onFileUpload)="FileUploaded($event)">

        </FileUpload>
      </div>
      <table class="table-sm table-bordered">
        <thead>
         
          <tr>
            <th>#</th>
            <th>{{captions.file}}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let f of correspondencAttachments; let i=index;">
            <td>{{(1+i)}}</td>
            <td> <button class="link" (click)="DownloadDocument(f.fileId)">{{f.fileName}}</button></td>
            <td><button (click)="RemoveFile('syllabusFiles',f)" title={{captions.delete}}>{{captions.delete}}</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</mat-dialog-content>
<div class="_cs_size-w-percent--100 _cs_p-left--sm _cs_p-right--sm">
  <mat-dialog-actions align="end">
    <button (click)="SendLetter()" class="_cs_button _cs_button-sm _cs_button-border--positive _cs_m-left--md">
      <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.send}}</span>
    </button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button [mat-dialog-close]="true" class="_cs_button _cs_button-sm _cs_button-border--positive _cs_m-left--md">
      <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.cancel}}</span>
    </button>
  </mat-dialog-actions>
</div>

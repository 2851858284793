export class User {
  constructor(public id: number, public name: string) { }
}

export interface IUserResponse {
  total: number;
  results: User[];
}


export class LecturerUserData {
  public personId: number;
  public interfaceLanguage: number;
  public sName: string;
  public fName: string;
  public profileImageFileStream: string;
    }

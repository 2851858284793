import { Pipe, PipeTransform } from '@angular/core';

export class Language {
  caption: string;
  id: number;
}


@Pipe({
  name: 'LanguagePipe',
  pure: false
})
export class LanguagePipe implements PipeTransform {
  transform(language: Language[], filter: any, current: any) {
    let ar = new Array<Language>();
    for (let i = 0; i < language.length; i++) {
      if (filter.filter(item => item.languageId!=language[i].id).length>0) {
        ar.push(language[i]);
      }
     
    }
    if (current != null) {
      alert(current);
      ar.push({ caption: current.caption, id: current.languageId });
    }
    //alert(language.length);
    //alert(filter.length);
    //alert(ar.length);
    return ar;
  }
}

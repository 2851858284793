<div class="_cs_flex _cs_size-w-percent--100">
  <div class="_cs_size-w-percent--100 _cs_p-left--lg _cs_p-right--lg _cs_p-down--lg">
    <div class="_cs_size-h-percent--100 sidebarbg _cs_flex">

      <div class="sidebarbg__wrapper _cs_p-left--none-i _cs_size-w-percent--100 _cs_flex _cs_flex-d--column">

        <div class="_cs_m-down--md _cs_m-top--xxl">
          <div
            class="_cs_p-xs _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_border-down--primary-6 _cs_flex _cs_flex-a--center">
            <span
              class="_cs_icon _cs_icon-xl _cs_shadow-primary-6-0--2 _cs_border-radius--full _cs_border--primary-6 _cs_m-right--md">
              <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-news"></span>

            </span>
            <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold
                                               _cs_color-primary-6">{{captions.studyMaterial}}</span>
          </div>
        </div>
        <div class="_cs_size-w-percent--100">
          <SearchStudGroup (onFilter)="Filter($event)">

          </SearchStudGroup>

          <div class="_cs_border-radius--sm _cs_p-sm" *ngIf="_filter&&_filter.groupId">
            <FileUpload (onFileUpload)="FileUploaded($event)">
          
          
            </FileUpload>
          </div>
          <div class="_cs_col-xs-12 _cs_table-container _cs_scrolled-table">


            <div class="_cs_color-bg--primary-5 _cs_size-w-percent--100  _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_flex _cs_flex-wrap _cs_position-relative _cs_p-sm _cs_p-down--none">
              <button (click)="SaveMaterila()"
                      class="_cs_button _cs_button-sm _cs_button-border--positive _cs_border-radius--sm _cs_m-down--sm _cs_m-left--xs _cs_m-right--xs ">
                <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-500 _cs_label-t-u _cs_color-primary-6">
                  {{captions.save}} 
                </span>
              </button>

            </div>
            <div *ngIf="syllabusData">

              <b>{{captions.syllabus}}:</b> {{syllabusData.syllabus.subjectCaption}}


            </div>

            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{captions.weekDay}}</th>
                  <th>{{captions.date}}</th>
                  <th>{{captions.downloadDocument}}</th>
                  <th>{{captions.caption}}</th>
                  <th>{{captions.description}}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let s of materialList; let k=index">
                  <td>{{(k+1)}}</td>
                  <td (click)="toggleCaption({rowId:(k+1),cellValue:'weekId'},s)">
                    <mat-form-field *ngIf="editCell.rowId==(k+1) && editCell.cellValue=='weekId'">
                      <mat-select [(ngModel)]="s.weekId">
                        <mat-option *ngFor="let ls of weekList" [value]="ls.id" (click)="s.weekName=ls.caption">
                          {{ls.caption}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div *ngIf="!(editCell.rowId==(k+1) && editCell.cellValue=='weekId')">
                      {{s.weekName}} &nbsp;
                    </div>
                  </td>
                  <td (click)="toggleCaption({rowId:(k+1),cellValue:'forDayDate'},s)">
                    <input matInput type="date" [(ngModel)]="s.forDayDate"
                           *ngIf="editCell.rowId==(k+1) && editCell.cellValue=='forDayDate'" />
                    <div *ngIf="!(editCell.rowId==(k+1) && editCell.cellValue=='forDayDate')">
                      {{s.forDayDate}} &nbsp;
                    </div>

                  </td>
                  <td><button class="link" (click)="DownloadDocument(s.fileStreamId)">{{s.fileName}}</button></td>
                  <td>
                    <input matInput type="text" [(ngModel)]="s.fileName" />
                  </td>
                  <td>
                    <input class="inputWidht" type="text" [(ngModel)]="s.description" />
                  </td>
                  <td>
                    <button class="_cs_button _cs_button-border--positive _cs_button-xs" (click)="FileDelete(s)">
                      <span class="_cs_label _cs_label-xs _cs_label-500 _cs_color-primary-6">{{captions.delete}}</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>


        </div>
      </div>

    </div>
  </div>
</div>

export const georgian:any= {
  'en-GB': {
    'schedule': {
      
      "day": "დღე",
      "week": "კვირა",
      "workWeek": "სამუშაო კვირა",
      "month": "თვე",
      "agenda": "დღის წესრიგი",
      "weekAgenda": "სამუშაო დღის წესრიგი",
      "workWeekAgenda": "სამუშაო კვირის დღის წესრიგი",
      "monthAgenda": "თვის დღის წესრიგი",
      "today": "დღეს",
      "noEvents": "არა მოვლენები",
      "emptyContainer": "ცარიელი კონტეინერი",
      "allDay": "თმელი დღე",
      "start": "დაწყება",
      "end": "დამთავრება",
      "more": "მეტი",
      "close": "დახურვა",
      "cancel": "გაუქმება",
      "noTitle": "უსათაურო",
      "delete": "წაშლა",
      "deleteEvent": "მოვლენის წაშლა",
      "deleteMultipleEvent": "მრავალი ღონისძიების წაშლა",
      "selectedItems": "არჩეული ელემენტები",
      "deleteSeries": "სერიების წაშლა",
      "edit": "რედაქტირება",
      "editSeries": "სერიების რედაქტირება",
      "editEvent": "რედაქტირება ღონისძიება",
      "createEvent": "ივენთის შექმნა",
      "subject": "საგანი",
      "addTitle": "დაამატე სათაური",
      "moreDetails": "მეტი ინფორმაცია",
      "save": "შენახვა",
      "editContent": "შინაარსის რედაქტირება?",
      "deleteRecurrenceContent": "წაშალეთ განმეორებადი შინნარსი?",
      "deleteContent": "შინაარსის წაშლა?",
      "deleteMultipleContent": "მრავალჯერადი შინნარსის წაშლა?",
      "newEvent": "ახალი მოვლენა",
      "title": "სათაური",
      "location": "მდებარეობა",
      "description": "აღწერილობა",
      "timezone": "დროის სარტყელი",
      "startTimezone": "დაიწყეთ დროის სარტყელი",
      "endTimezone": "დაასრულეთ დროის სარტყელი",
      "repeat": "გამეორება",
      "saveButton": "შენახვა",
      "cancelButton": "გაუქმების ღილაკი",
      "deleteButton": "წაშლის წილაკი",
      "recurrence": "განმეორება",
      "wrongPattern": "არასწორი შაბლონი.",
      "seriesChangeAlert": "შეტყობინება ცვლილებების შესახებ.",
      "createError": "შეცდომის შექმნა.",
      "recurrenceDateValidation": "განმეორება თარიღის დამოწმება.",
      "sameDayAlert": "იმავე დღეს შეტყობინება.",
      "editRecurrence": "გამეორების რედაქტირება",
      "repeats": "იმეორებს",
      "alert": "შეტყობინება",
      "startEndError": "დაწყება დასრულების შეცდომა.",
      "invalidDateError": "არასწორი თარიღის შეცდომა.",
      "ok": "კარგი",
      "occurrence": "მოვლენა",
      "series": "სერიები",
      "previous": "წინა",
      "next": "შემდეგი",
      "timelineDay": "დღის ქრონოლოგია",
      "timelineWeek": "კვირის ქრონოლოგია",
      "timelineWorkWeek": "სამუშაო კვირის ქრონოლოგია",
      "timelineMonth": "თვის ქრონოლოგია"
    },
    "recurrenceeditor": {
      "none": "არცერთი",
      "daily": "ყოველდღიურად",
      "weekly": "ყოველკვირეულად",
      "monthly": "ყოველთვიურად",
      "month": "თვე",
      "yearly": "წლიურად",
      "never": "არასდროს",
      "until": "მანამდე",
      "count": "დათვლა",
      "first": "პირველი",
      "second": "მეორე",
      "third": "მესამე",
      "fourth": "მეოტხე",
      "last": "ბოლო",
      "repeat": "გამეორება",
      "repeatEvery": "გაიმეორეთ ყველა",
      "on": "ზე",
      "end": "დასასრული",
      "onDay": "დღეს",
      "days": "დღეები)",
      "weeks": "კვირეები)",
      "months": "თვეები)",
      "years": "წლები)",
      "every": "ყველა",
      "summaryTimes": "შემაჯამებელი დრო)",
      "summaryOn": "რეზიუმე ჩართულია",
      "summaryUntil": "რეზიუმე სანამ",
      "summaryRepeat": "გაიმეორეთ რეზიუმე",
      "summaryDay": "დღის რეზიუმე)",
      "summaryWeek": "კვირის რეზიუმე)",
      "summaryMonth": "თვის რეზიუმე)",
      "summaryYear": "წლის რეზიუმე)",
      "monthWeek": "თვის კვირა",
      "monthPosition": "თვის პოზიცია",
      "monthExpander": "თვის გაფართოება",
      "yearExpander": "წლის გაფართოება",
      "repeatInterval": "ინტერვალის გამეორება"
    },
    "calendar": {
      "today": "დღეს"
    }
  }
}

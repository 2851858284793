import { Component, Inject, EventEmitter, Injectable, Pipe, PipeTransform, Input, Output, ApplicationRef, ComponentFactoryResolver, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Data } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { MatSelectTrigger, MatSelectChange } from '@angular/material/select';
import { SelectionModel } from '@angular/cdk/collections';
import { of as ofObservable, Observable, BehaviorSubject, throwError, empty } from 'rxjs';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { catchError, map, startWith, switchMap, debounceTime, tap, finalize, filter } from 'rxjs/operators';
import { Logs } from 'selenium-webdriver';

import { SharedService } from '../../Lecturer/Service/SharedService';



@Component({
  selector: 'ProfileImage',
  templateUrl: 'ProfileImage.html',
  styleUrls: ['ProfileImage.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileImage {
  
  @Input() fileId: Observable<string>;
  @Input() width: string = "150px";
  @Input() height: string = "200px";
  @Input() imageClass: string = null;

  constructor(
    private cd: ChangeDetectorRef,
    private SharedService: SharedService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router) {


  }
  ngOnInit() {
    
  }

  ngAfterContentInit(): void {

    this.fileId.subscribe(vl => {
      if (vl != null && vl.length > 3) {
        this.getImageFromService(vl);
      }

      this.cd.markForCheck();
    });

  }


  imageToShow: any;

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }


  isImageLoading: boolean=true;

  getImageFromService(fileId:any) {
    this.isImageLoading = true;
    

      this.SharedService.getImage(fileId).subscribe(data => {
        this.createImageFromBlob(data);
        this.isImageLoading = false;
      }, error => {
        this.isImageLoading = false;
        console.log(error);
      });
    
  }


}



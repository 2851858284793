import { Component } from '@angular/core';
import { L10n, loadCldr } from '@syncfusion/ej2-base';

import { georgian } from './LanguageModel/Caption.GE';

import localeEs from '@angular/common/locales/ka';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeEs);



declare var require: any




declare var require: any


loadCldr(
  require('cldr-data/supplemental/numberingSystems.json'),
  require('./LanguageJson/ka.json'),
  require('cldr-data/main/en-GB/numbers.json'),
  require('cldr-data/main/en-GB/timeZoneNames.json'));

L10n.load(georgian);


loadCldr(
  require('cldr-data/main/en/ca-gregorian.json'),
  require('cldr-data/main/en/numbers.json'),
  require('cldr-data/main/en/timeZoneNames.json'));




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
}

<div class="_cs_flex _cs_size-w-percent--100">
  <div class="_cs_size-w-percent--100 _cs_p-left--lg _cs_p-right--lg _cs_p-down--lg">
    <div class="_cs_size-h-percent--100 sidebarbg _cs_flex">

      <div class="sidebarbg__wrapper _cs_p-left--none-i _cs_size-w-percent--100 _cs_flex _cs_flex-d--column">

        <div class="_cs_m-down--lg">
          <div
            class="_cs_p-md _cs_opacity-bg-primary-0-0--1 _cs_shadow-primary-0-0--1 _cs_border-radius--sm  _cs_activeitem-opacity-bg-primary-2-0--1">
            <span
              class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold _cs_color-primary-2">{{captions.card}}</span>
          </div>
        </div>

        <div class="_cs_flex" *ngIf="lecturerLoad">

          <table cdk-table [dataSource]="lecturerLoad" class="table table-bordered table-striped table-sm">
            <ng-container matColumnDef="syllabusCaption">
              <th mat-header-cell *matHeaderCellDef> {{captions.subjectName}} </th>
              <td mat-cell *matCellDef="let element"> {{element.syllabusCaption}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="subjectTypeCaption">
              <th mat-header-cell *matHeaderCellDef> {{captions.subjectType}} </th>
              <td mat-cell *matCellDef="let element"> {{element.subjectTypeCaption}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="credit">
              <th mat-header-cell *matHeaderCellDef> {{captions.credit}} </th>
              <td mat-cell *matCellDef="let element"> {{element.credit}} </td>
            </ng-container>
            <ng-container matColumnDef="groupName">
              <th mat-header-cell *matHeaderCellDef> groupName </th>
              <td mat-cell *matCellDef="let element"> {{element.groupName}} </td>
            </ng-container>
            <!-- Symbol Column -->
            <ng-container matColumnDef="year">
              <th mat-header-cell *matHeaderCellDef> {{captions.yearName}} </th>
              <td mat-cell *matCellDef="let element"> {{element.year}} </td>
            </ng-container>
            <ng-container matColumnDef="semester">
              <th mat-header-cell *matHeaderCellDef> {{captions.semName}} </th>
              <td mat-cell *matCellDef="let element"> {{element.semester}} </td>
            </ng-container>
            <ng-container matColumnDef="facultyName">
              <th mat-header-cell *matHeaderCellDef> facultyName </th>
              <td mat-cell *matCellDef="let element"> {{element.facultyName}} </td>
            </ng-container>
            <ng-container matColumnDef="studylevelName">
              <th mat-header-cell *matHeaderCellDef> studylevelName </th>
              <td mat-cell *matCellDef="let element"> {{element.studylevelName}} </td>
            </ng-container>
            <ng-container matColumnDef="lectureCaption">
              <th mat-header-cell *matHeaderCellDef> LectureCaption </th>
              <td mat-cell *matCellDef="let element"> {{element.lectureCaption}} </td>
            </ng-container>
            <ng-container matColumnDef="duration">
              <th mat-header-cell *matHeaderCellDef> duration </th>
              <td mat-cell *matCellDef="let element"> {{element.duration}} </td>
            </ng-container>
            <ng-container matColumnDef="houars">
              <th mat-header-cell *matHeaderCellDef> houars </th>
              <td mat-cell *matCellDef="let element"> {{element.houars}} </td>
            </ng-container>
            <!--<ng-container matColumnDef="weeksOrDays">
        <th mat-header-cell *matHeaderCellDef> weeksOrDays </th>
        <td mat-cell *matCellDef="let element"> {{element.weeksOrDays}} </td>
      </ng-container>-->
            <ng-container matColumnDef="shedulerTypeCaption">
              <th mat-header-cell *matHeaderCellDef> shedulerTypeCaption </th>
              <td mat-cell *matCellDef="let element"> {{element.shedulerTypeCaption}} </td>
            </ng-container>
            <ng-container matColumnDef="studCount">
              <th mat-header-cell *matHeaderCellDef> studCount </th>
              <td mat-cell *matCellDef="let element"> {{element.studCount}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: columsToDisplay;"></tr>
          </table>

        </div>

      </div>

    </div>
  </div>
</div>

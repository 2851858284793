export class EvaluationHeader {
  public componentOnSyllabusId: number;
  public lectureTypeID: number;
  public calculationTypeID: number;
  public calculationCaption: string;
  public compOrder: number;
  public evalutionTypeId: number;
  public evalutionTypeCaption: string;
  public maxCompCountOnWeeks: number;
  public maxEnterMark: number;
  public minEnterMark: number;
  public maxMark: number;
  public maxRanking: number;
  public scoreEditorTypeID: number;
  public componentName: string;
  public syllabusComponentTimeTableId: number;
  public weekDayId: number;
  public weekId: number;
  public startTime: string;
  public durationTimeInMinute: number;
  public startDateTime: Date;
  public weekNameGEO: string;
  public dayName: string;
}
export class EvaluationMarks {
  //public componentOnSyllabusId: number;
  //public syllabusComponentTimeTableId: number;
  public sTT: number;
  //public studDailyEvaluationId: number;
  public sE: number;
  //public subjectEnrollId: number;
  public sd: number;
  public mark: number;
}

export class SaveEvaluationMarks {
  //public componentOnSyllabusId: number;
  //public syllabusComponentTimeTableId: number;
  public sTT: number;
  //public studDailyEvaluationId: number;
  public sE: number;
  //public subjectEnrollId: number;
  public sd: number;
  public mark: number;
  public calculationTypeID: number;
  public maxEnterMark: number;
  public minEnterMark: number;
  public maxMark: number;
  public maxRanking: number;
  public evalutionTypeId: number;
  public componentOnSyllabusId: number;
  public maxCompCountOnWeeks: number;

}

export class StudentListOnLecture {
  //public enrollHistoryId: number;
  public ed: number;
  //public personID: number;
  //public studentId: number;
  public fN: string;
  public sN: string;
  public pn: string;
  public pr: string;
  public fn: string;
  public sl: string;
  //public evaluationMarks: Array<EvaluationMarks>;
  public eM: Array<number>;
  public sumMarks: string;
}

export class StudentListOnLectureForView {
  public enrollHistoryId: number;
  public personID: number;
  public studentId: number;
  public fName: string | null;
  public sName: string | null;
  public pn: string | null;
  public facultyCaption: string;
  public studyLevelCaption: string;
  public semester: number;
}

export class StudentEvaluationGenerator {
  public evaluationHeader: Array<EvaluationHeader>;
 // public evaluationMarks: Array<EvaluationMarks>;
  public studentListOnLecture: Array<StudentListOnLecture>;
}

export class FilterGroups {
  public courseId: number;
  public groupId: number;
  public lacureTypeId: number;
}

function binarySearch(array: Array<EvaluationMarks>, key) {
  var lo = 0,
    hi = array.length - 1,
    mid,
    element;
  while (lo <= hi) {
    mid = ((lo + hi) >> 1);
    element = array[mid].sd;
    if (element < key) {
      lo = mid + 1;
    } else if (element > key) {
      hi = mid - 1;
    } else {
      return mid;
    }
  }
  return -1;
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../_models';
import { ChangePasswordData } from '../../Models/Profile';
import { ReturnResultValue } from '../../Models/ResultReturnParameters';


@Injectable({ providedIn: 'root' })
export class UserService {
  private baseUrl = window.location.origin + '/api/Auth';
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(`""/users`);
    }

    getById(id: number) {
        return this.http.get(`""/users/${id}`);
    }

    register(user: User) {
      return this.http.post(`${this.baseUrl}/InsertUser`, user);
    }

    update(user: User) {
        return this.http.put(`""/users/${user.id}`, user);
    }

    changePassword(newPassword: ChangePasswordData): Promise<boolean> {
    

    return this.http.post<any>(`${this.baseUrl}/ChangePassword`, newPassword).toPromise().catch(this.handleError);
  }

    resetPassword(resetData: any): Promise<ReturnResultValue> {
        return this.http.post<any>(`${this.baseUrl}/ResetPassword`, resetData).toPromise().catch(this.handleError);
    }

    ResetCodeToEmail(resetData: any): Promise<ReturnResultValue> {

        return this.http.post<any>(`${this.baseUrl}/ResetCodeToEmail`, resetData).toPromise().catch(this.handleError);
    }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}

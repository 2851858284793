import { ViewChild, Component } from "@angular/core";
import { Caption, PortalLanguageCaption } from "../LanguageModel/Caption";
import { InformationBoardResult, Infofilter, InformationBoardList } from "../Models/InformationBoardModel";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTreeFlattener, MatTreeFlatDataSource } from "@angular/material/tree";
import { FlatTreeControl } from "@angular/cdk/tree";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from "../Lecturer/Service/SharedService";
import { HttpClient } from "@angular/common/http";
import { InformationBoardService } from "../Lecturer/Service/InformationBoardService";
import { LanguageService } from "../Service/LanguageService";
import { merge, of as observableOf } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { ViewNewsComponent } from "./ViewNews/view-news/ViewNews";
import { ChartOptions, ChartType } from "chart.js";
import { Label, SingleDataSet, BaseChartDirective } from "ng2-charts";
import { CardService } from "../Lecturer/Service/CardService";






@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  isDataLoaded: boolean = false;
  public captions: Caption;
  public informationBoardResult: InformationBoardResult;
  public infoTypeList: any;
  resultsLength = 0;
  public _filter: Infofilter = new Infofilter();


  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      text: this.isCaptionNull().workloadAccording,
      display: true
    }
  };
  public pieChartOptions1: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      text: this.isCaptionNull().submissionRate,
      display: true
    }
  };
  public pieChartOptions2: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      text: this.isCaptionNull().failedRate,
      display: true
    }
  };

  isCaptionNull(): Caption {
    if (this.captions != null) {
      return this.captions;
    }
    else {
      this.captions = this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];
      return this.captions;
    }
  }


  public pieChartLabels: Label[] = new Array<Label>(); //= ['Download Sales', 'In-Store Sales', 'Mail Sales'];
  public pieChartData: SingleDataSet = new Array<number>(); //= [300, 500, 100];
  public pieChartType: ChartType = 'doughnut';
  public pieChartLabels1: Label[] = new Array<Label>(); //= ['Download Sales', 'In-Store Sales', 'Mail Sales'];
  public pieChartData1: SingleDataSet = new Array<number>(); //= [300, 500, 100];
  public pieChartType1: ChartType = 'doughnut';
  public pieChartLabels2: Label[] = new Array<Label>(); //= ['Download Sales', 'In-Store Sales', 'Mail Sales'];
  public pieChartData2: SingleDataSet = new Array<number>(); //= [300, 500, 100];
  public pieChartType2: ChartType = 'doughnut';
  public pieChartLegend = true;
  public pieChartPlugins = [{
    afterLayout: function (chart) {
      chart.legend.legendItems.forEach(
        (label) => {
          let value = chart.data.datasets[0].data[label.index];

          label.text += ' ' + value;
          return label;
        }
      )
    }
  }];
  public dynHeight = 250;
  public dynWidth = 900;
  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
  direction = 100;




  constructor(public dialog: MatDialog,
    private activatedRoute: ActivatedRoute, private route: ActivatedRoute,

    private router: Router,
    public LanguageService: LanguageService,
    public informationBoardService: InformationBoardService,
    public sharedService: SharedService,
    public cardService: CardService,
    private http: HttpClient) {
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];



  }

  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }
  ngOnInit() {
    this.sharedService.GetInformationBoardTypes().then(e => {
      this._filter.infoTypes = new Array<number>();
      for (let i = 0; i < e.length; i++) {
        this._filter.infoTypes.push(e[i].id);
      }
      this._filter.take = 10;
      this._filter.skip = 0;
      this.informationBoardService.GetInfoList(this._filter).then(e => { this.informationBoardResult = e; this.resultsLength = e.count; });
      this.GenerateTypeMap(e);
    });




    this.cardService.LecturerLoad().then(e => {
      //this.studentsSubjects = e.studentProfile;
      let yearS = new Array<StudyYearSemesterGroup>();
      let CurrentYear = "";
      let CurrentSemester = "";
      this.isDataLoaded = false;


      for (let i = 0; i < e.length; i++) {
        if (CurrentYear == e[i].year && CurrentSemester == e[i].semester) {
          let cur = yearS.filter(e => e.studyYear == CurrentYear && e.studySemester == CurrentSemester)[0];
          cur.subjectCount += 1;
          cur.sumHours += e[i].houars;
          cur.failedCount += e[i].failedCount;
          cur.submission += e[i].submission;
          cur.studentsCount += e[i].studCount;
          //cur.sumGPA += (e[i].gpa * e[i].credit);
          //cur.sumMark += e[i].sumMark;
         // cur.sumCreditsGPA += e[i].credit;
        }
        else {
          CurrentYear = e[i].year;
          CurrentSemester = e[i].semester;
          yearS.push({
            studyYear: e[i].year,
            studySemester: e[i].semester, subjectCount: 1, sumHours: e[i].houars, failedCount: e[i].failedCount, submission: e[i].submission,
            studentsCount: e[i].studCount
            //sumMark: e[i].sumMark, sumGPA: (e[i].gpa * e[i].credit), type: 1, sumCreditsGPA: e[i].credit
          })
        }
      }
      for (let lt = 0; lt < yearS.length; lt++) {
        this.pieChartLabels.push(yearS[lt].studyYear + ", " + yearS[lt].studySemester);
        this.pieChartData.push(yearS[lt].sumHours);
        this.pieChartLabels1.push(yearS[lt].studyYear + ", " + yearS[lt].studySemester);
        this.pieChartData1.push(Math.round((yearS[lt].submission / yearS[lt].studentsCount) * 100));
        this.pieChartLabels2.push(yearS[lt].studyYear + ", " + yearS[lt].studySemester);
        this.pieChartData2.push(Math.round((yearS[lt].failedCount / yearS[lt].studentsCount) * 100));
      }

      setTimeout(() => {
        this.isDataLoaded = true;
      }, 0);
      ////this.pieChartLabels=
    });



  }


  BehavRet(image: string): BehaviorSubject<string> {
    return new BehaviorSubject(image);
  }

  OpenFullData(v: InformationBoardList): void {
    let dialogRef = this.dialog.open(ViewNewsComponent, {
      panelClass: 'my-centered-dialog',
      height: '80%',
      width: '80%',
      disableClose: true,
      data: v
    });

    dialogRef.afterClosed().subscribe(result => {

      //this.animal = result;
    });
  }
  FilterNews(node: ExampleFlatNode) {


    this._filter.infoTypes = new Array<number>();
    this._filter.infoTypes.push(node.id);
    this._filter.take = 10;
    this._filter.skip = 0;
    this.informationBoardService.GetInfoList(this._filter).then(e => { this.informationBoardResult = e; this.resultsLength = e.count; });
  }
  GenerateTypeMap(data: any) {
    let boardTypeList = new Array<boardType>();
    let boardChilde = new Array<boardType>();
    for (let i = 0; i < data.length; i++) {
      if (data[i].parentid == null) {
        boardTypeList.push({ id: data[i].id, parentid: data[i].parentid, name: data[i].text, icon: data[i].icon });
      }
      else {
        boardChilde.push({ id: data[i].id, parentid: data[i].parentid, name: data[i].text, icon: data[i].icon });
      }
    }
    for (let i = 0; i < boardTypeList.length; i++) {
      this.SubTypeGenerate(boardTypeList[i], boardChilde);
    }
    //this.dataSource.data = boardTypeList;
    //console.log(boardTypeList);
  }

  SubTypeGenerate(board: boardType, array: Array<boardType>) {
    for (let i = 0; i < array.length; i++) {
      if (board.id == array[i].parentid) {
        if (board.children == null) {
          board.children = new Array<boardType>();
        }
        board.children.push(array[i]);
        this.SubTypeGenerate(array[i], array);
      }
    }
  }

}
interface boardType {
  id: number;
  name: string;
  parentid: number;
  icon: string;
  children?: boardType[];
}
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  icon: string;
  id: number;
}
export class StudyYearSemesterGroup {
  studyYear: string;
  studySemester: string;
  sumHours: number;
  //sumCreditsGPA: number;
  //sumMark: number;
  //sumGPA: number;
  subjectCount: number;
  public failedCount: number;
  public submission: number;
  public studentsCount: number;
  //type: number;
}

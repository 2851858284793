import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';
import { AlertService, AuthenticationService } from '../_services';
import { RecoverPassword } from '../RecoverPassword/RecoverPassword';
import { MatDialog } from '@angular/material/dialog';
import { Caption, PortalLanguageCaption } from '../../LanguageModel/Caption';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css']
})

export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
  public captions: Caption;
  geoActive: number = 1;

  constructor(public dialog: MatDialog,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private authenticationService: AuthenticationService,
        private alertService: AlertService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) { 
            this.router.navigate(['/']);
        }
    }


  selectLanguage(language: number) {
    localStorage.setItem('languageMainPage', language.toString());
    this.geoActive = language;
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("languageMainPage")) - 1];
  }

  ngOnInit() {


    if (localStorage.getItem("languageMainPage") != null) {
      this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("languageMainPage")) - 1];
      this.geoActive = parseInt(localStorage.getItem("languageMainPage"));
    }
    else {
      this.captions = PortalLanguageCaption[0];
      this.geoActive = 1;
    }


        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }


  

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                if (data) {
                  this.router.navigate([this.returnUrl]);
                }
                else {
                  this.loading = false;
                  alert("მომხმარებელი ან პაროლი არასწორია!");
                }
              },
              error => {
                this.alertService.error(error);
                this.loading = false;
                alert("მომხმარებელი ან პაროლი არასწორია!");
              });
    }

  RecoverPassword() {
    let dialogRef = this.dialog.open(RecoverPassword, {
      panelClass: 'my-centered-dialog',
      height: '400px',
      width: '500px',
      disableClose: true,
      data: { formType: 1, recipientsList: null }
    });

    dialogRef.afterClosed().subscribe(result => {

      //this.animal = result;
    });
  }


}

import { Component, Inject, EventEmitter, Injectable, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { MatSelectTrigger, MatSelectChange } from '@angular/material/select';
import { SelectionModel } from '@angular/cdk/collections';
import { of as observableOf, Observable, BehaviorSubject, throwError, empty, merge } from 'rxjs';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { IUserResponse, User } from 'src/app/Models/Users';
import { catchError, map, startWith, switchMap, debounceTime, tap, finalize } from 'rxjs/operators';
import { Logs } from 'selenium-webdriver';


import { UIfilter } from '../../../Models/filters';
import { StudentsListData, RetStudList } from '../../../Models/StudentsList';
import { FilterGroups, StudentListOnLectureForView } from '../../../Models/Graids';
import { GraideService } from '../../../Lecturer/Service/GraideService';
import { Caption, PortalLanguageCaption } from '../../../LanguageModel/Caption';



@Component({
  selector: 'PersoneListDialog',
  templateUrl: 'PersoneListDialog.html',
  styleUrls: ['PersoneListDialog.css']//,
  // providers: [ChecklistDatabase]
})



export class PersoneListDialog {
  

  public studentsList: RetStudList;
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  selectedRow: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['select', 'fName', 'sName', 'pn', 'facultyCaption', 'studyLevelCaption', 'semester'];
  public entitiesDataSource: MatTableDataSource<StudentListOnLectureForView> = new MatTableDataSource();
  selection = new SelectionModel<StudentListOnLectureForView>(true, []);
  private _filter: FilterGroups;
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.entitiesDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.entitiesDataSource.data.forEach(row => this.selection.select(row));
  }

  public captions: Caption;

  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<PersoneListDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private activatedRoute: ActivatedRoute,
    //private SharedService: SharedService,
    private route: ActivatedRoute,
    private location: Location,
    private graideService: GraideService,
    //private StudListService: StudListService,
    private router: Router, private fb: FormBuilder) {

    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];


  }
  ngOnInit() {
    this.entitiesDataSource.paginator = this.paginator;
    //merge(//this.sort.sortChange,
    //  this.paginator.page)
    //  .pipe(
    //    //startWith({}),
    //    switchMap(() => {
    //      this.isLoadingResults = true;
    //      return this.StudListService.StudentsList(this._filter.FilterPersone,
    //        this.paginator.pageSize, this.paginator.pageIndex);
    //    }),
    //    map(data => {
    //      // Flip flag to show that loading has finished.
    //      this.isLoadingResults = false;
    //      this.isRateLimitReached = false;
    //      this.resultsLength = data.count;

    //      return data.studentData;
    //    }),
    //    catchError(() => {
    //      this.isLoadingResults = false;
    //      // Catch if the GitHub API has reached its rate limit. Return empty data.
    //      this.isRateLimitReached = true;
    //      return observableOf([]);
    //    })
    //  ).subscribe(data => this.entitiesDataSource.data = data);


  }

  Filter(filter: FilterGroups) {
    console.log(filter);
    this._filter = filter;
    if (filter) {
      this.graideService.GetStudentListOnLecture(filter).then(e => { this.entitiesDataSource.data = e });
    }
  }

  RecipientsData(): void {
   // this.data = this.selection;
    this.dialogRef.close(this.selection);
  }
}

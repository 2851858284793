import { Component, ChangeDetectorRef, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ObjectForFillSelect } from '../../../Models/ObjectForFillSelect';
import { LecturerReadCourses } from '../../../Models/Evaluations';
import { SharedService } from '../../../Lecturer/Service/SharedService';
import { GraideService } from '../../../Lecturer/Service/GraideService';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterGroups } from '../../../Models/Graids';
import { Caption, PortalLanguageCaption } from '../../../LanguageModel/Caption';

@Component({
  selector: 'SearchStudGroup',
  templateUrl: './SearchStudGroup.html',
  styleUrls: ['./SearchStudGroup.css']
  //,
  //changeDetection: ChangeDetectionStrategy.OnPush
})
/** SearchStudGroup component*/
export class SearchStudGroup {
  facultySelected: any;
  fakultyList: Array<ObjectForFillSelect>;
  selectedStudyLevel: any;
  studyLevelList: Array<ObjectForFillSelect>;
  studyYear: Array<ObjectForFillSelect>;
  selectedYear: any;
  studySemester: Array<ObjectForFillSelect>;
  studySemesterSelected: any;
  studyCourse: Array<ObjectForFillSelect>;
  selectedstudyCourse: any;
  lecutreTypeList: Array<ObjectForFillSelect>;
  lecutreTypeselected: any;
  lectureList: Array<LecturerReadCourses>;
  groupSelected: LecturerReadCourses;
  groupList: Array<LecturerReadCourses>;
  @Output() onFilter = new EventEmitter<FilterGroups>();

  public captions: Caption;
  constructor(private SharedService: SharedService,
    private GraideService: GraideService,

    private route: ActivatedRoute,
    private router: Router, private cdr: ChangeDetectorRef) {

    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];

    this.GraideService.GetLecturerGroupsList().then(e => {
      this.lectureList = e as Array<LecturerReadCourses>;

      this.FillFaculty();

    });
  }

  FillFaculty(): void {
    this.fakultyList = new Array<ObjectForFillSelect>();
    for (let i = 0; i < this.lectureList.length; i++) {
      let add = true;
      for (let k = 0; k < this.fakultyList.length; k++) {
        if (this.fakultyList[k].id == this.lectureList[i].facultyId) {
          add = false;
          break;
        }
      }
      if (add) {
        this.fakultyList.push({ id: this.lectureList[i].facultyId, caption: this.lectureList[i].facultyName });
      }

    }
  }
  fakultyCahnged(vl: any) {
    //alert(vl);
    //if (this.studySemester!=null)
    //  this.studySemester.splice(0);
    //if (this.studyYear != null)
    //  this.studyYear.splice(0);
    this.lecutreTypeselected = null;
    this.selectedStudyLevel = null;
    this.selectedstudyCourse = null;
    this.groupSelected = null;
    this.selectedYear = null;
    this.studySemesterSelected = null;
    if (this.studyCourse != null)
      this.studyCourse.splice(0);
    if (this.lecutreTypeList != null)
      this.lecutreTypeList.splice(0);
    if (this.groupList != null)
      this.groupList.splice(0);

    this.studyLevelList = new Array<ObjectForFillSelect>();
    for (let i = 0; i < this.lectureList.length; i++) {
      if (this.lectureList[i].facultyId == vl.value) {
        let add = true;
        for (let k = 0; k < this.studyLevelList.length; k++) {
          if (this.studyLevelList[k].id == this.lectureList[i].studylevelId) {
            add = false;
            break;
          }
        }
        if (add) {
          this.studyLevelList.push({ id: this.lectureList[i].studylevelId, caption: this.lectureList[i].studylevelName });
        }
      }

    }


  }

  studyLevelCahnged(vl: any) {

    this.lecutreTypeselected = null;
    this.selectedstudyCourse = null;
    this.groupSelected = null;
    this.selectedYear = null;
    this.studySemesterSelected = null;

    this.studyYear = new Array<ObjectForFillSelect>();
    for (let i = 0; i < this.lectureList.length; i++) {
      if (this.lectureList[i].facultyId == this.facultySelected && (this.lectureList[i].studylevelId == vl.value)) {
        let add = true;
        for (let k = 0; k < this.studyYear.length; k++) {
          if (this.studyYear[k].caption == this.lectureList[i].year) {
            add = false;
            break;
          }
        }
        if (add) {
          this.studyYear.push({ id: null, caption: this.lectureList[i].year });
        }
      }

    }
    this.LoadCurrentStudyYear();

  }

  LoadCurrentStudyYear() {
    this.SharedService.GetCurrentSession({ facultyId: this.facultySelected, studyLevelId: this.selectedStudyLevel, studyProgarmId: null, directionId: null }).
      then(e => {

        if (e) {


          this.selectedYear = e.studyYearCaption;

          this.YearChange({ value: e.studyYearCaption });
          this.studySemesterSelected = e.studySemesterCaption;
          this.SemesterChange({ value: e.studySemesterCaption })
        }
      });
  }


  YearChange(vl: any) {

    this.lecutreTypeselected = null;

    this.groupSelected = null;

    this.studySemesterSelected = null;
    this.selectedstudyCourse = null;

    this.studySemester = new Array<ObjectForFillSelect>();
    for (let i = 0; i < this.lectureList.length; i++) {
      if (this.lectureList[i].facultyId == this.facultySelected && (this.lectureList[i].studylevelId == this.selectedStudyLevel) && this.lectureList[i].year == vl.value) {
        let add = true;
        for (let k = 0; k < this.studySemester.length; k++) {
          if (this.studySemester[k].caption == this.lectureList[i].semester) {
            add = false;
            break;
          }
        }
        if (add) {
          this.studySemester.push({ id: null, caption: this.lectureList[i].semester });
        }
      }

    }
  }

  SemesterChange(vl: any) {

    this.lecutreTypeselected = null;

    this.groupSelected = null;
    this.selectedstudyCourse = null;


    this.studyCourse = new Array<ObjectForFillSelect>();
    for (let i = 0; i < this.lectureList.length; i++) {
      if (this.lectureList[i].facultyId == this.facultySelected
        && (this.lectureList[i].studylevelId == this.selectedStudyLevel)
        && this.lectureList[i].year == this.selectedYear && this.lectureList[i].semester == vl.value) {
        let add = true;
        for (let k = 0; k < this.studyCourse.length; k++) {
          if (this.studyCourse[k].id == this.lectureList[i].syllabusId) {
            add = false;
            break;
          }
        }
        if (add) {
          this.studyCourse.push({ id: this.lectureList[i].syllabusId, caption: this.lectureList[i].syllabusCaption });
        }
      }

    }
  }

  CourseChange(vl: any) {

    this.lecutreTypeselected = null;

    this.groupSelected = null;


    this.lecutreTypeList = new Array<ObjectForFillSelect>();
    for (let i = 0; i < this.lectureList.length; i++) {
      if (this.lectureList[i].facultyId == this.facultySelected
        && (this.lectureList[i].studylevelId == this.selectedStudyLevel)
        && this.lectureList[i].year == this.selectedYear
        && this.lectureList[i].semester == this.studySemesterSelected && this.lectureList[i].syllabusId == vl.value) {
        let add = true;
        for (let k = 0; k < this.lecutreTypeList.length; k++) {
          if (this.lecutreTypeList[k].id == this.lectureList[i].lectureTypeId) {
            add = false;
            break;
          }
        }
        if (add) {
          this.lecutreTypeList.push({ id: this.lectureList[i].lectureTypeId, caption: this.lectureList[i].lectureCaption });
        }
      }

    }
  }
  selectedLectueType: number;
  LectureTypeChange(vl: any) {

    this.groupSelected = null;
    this.selectedLectueType = vl.value;
    this.groupList = new Array<LecturerReadCourses>();
    for (let i = 0; i < this.lectureList.length; i++) {
      if (this.lectureList[i].facultyId == this.facultySelected
        && (this.lectureList[i].studylevelId == this.selectedStudyLevel)
        && this.lectureList[i].year == this.selectedYear
        && this.lectureList[i].semester == this.studySemesterSelected
        && this.lectureList[i].syllabusId == this.selectedstudyCourse && (vl.value == -1 || this.lectureList[i].lectureTypeId == vl.value)) {
        this.groupList.push(this.lectureList[i]);
      }

    }
  }

  ShowStudentList() {
    if (this.groupSelected != null) {
      let k: FilterGroups = {
        courseId: this.groupSelected.courseId, groupId: this.groupSelected.gourpId, lacureTypeId: this.selectedLectueType// this.groupSelected.lectureTypeId
      };
      this.onFilter.emit(k);
    }
    else {
      alert(this.captions.lectureGroupIsNoSelected);
    }
  }
}

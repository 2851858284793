
export interface StudentsListData {
  studId: number;
  fName: string;
  sName: string;
  persNumber: string;
  fakulty: string;
  studyLevel: string;
  studyProgram: string;
  enterYear: number;
  complateYear: number;
  naecReiting: number;
  birthDay: Date;
  mobile: string;
  email: string;
  semester: string;
  studyStatus: string;
  avgMark: number;
  sumCredits: number;
  personId: number;
}

export class RetStudList
{
   studentData: Array<StudentsListData>;
   count:number;
};

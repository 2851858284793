import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { CdkTableModule } from '@angular/cdk/table';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PersoneListDialog } from './Components/Dialogs/PersonListDialog/PersoneListDialog';
import { StatementFilter } from './Components/CorrespondenceFilter/StatementFilter';
import { AuthGuard } from './Identity/_guards';
import { LoginComponent } from './Identity/login';
import { AddWithCredentialsInterceptorService, ErrorInterceptor, fakeBackendProvider } from './Identity/_helpers';
import { FileUpload } from './Components/FileUpload/FileUpload';
import { ProfileImage } from './Components/ProfileImage/ProfileImage';
import { LanguageService } from './Service/LanguageService';
import { SanitizeHtmlPipe } from './Components/Pips/SanitizeHtml/SanitizeHtml';
import { Profile } from './Lecturer/Profile/Profile';
import { CorrespondenceMainForm } from './Lecturer/Correspondence/CorrespondenceMainForm';
import { CreateViewLetterDocument } from './Lecturer/Correspondence/Dialogs/CreateViewLetterDocument';
import { Card } from './Lecturer/Card/Card';
import { CardService } from './Lecturer/Service/CardService';
import { CorrespondenceService } from './Lecturer/Correspondence/Service/CorrespondenceService';
import { SharedService } from './Lecturer/Service/SharedService';
import { ProfileService } from './Lecturer/Service/ProfileService';
import { CourseService } from './Lecturer/Service/CourseService';
import { MarkJounal } from './Lecturer/Journal/MarkJounal';

import { GraideService } from './Lecturer/Service/GraideService';
import { TimeTable } from './Lecturer/TimeTable/TimeTable';
import { StudyMaterialComponent } from './Lecturer/StudyMaterial/StudyMaterial';
import { StudyMaterialService } from './Lecturer/Service/StudyMaterialService';
import { InformationBoardService } from './Lecturer/Service/InformationBoardService';
import { ViewNewsComponent } from './home/ViewNews/view-news/ViewNews';
import { CookieService } from 'ngx-cookie-service';
import { SearchStudGroup } from './Components/SearchFilters/SearchStudGroup/SearchStudGroup';
import { ChartsModule } from 'ng2-charts';
import { ChangePassword } from './Identity/ChangePassword/ChangePassword';
import { UserService } from './Identity/_services/user.service';
import { LanguagePipe } from './Models/language';
import { AlertComponent } from './Identity/_components';
import { RegisterComponent } from './Identity/register';
import { ScheduleAllModule } from '@syncfusion/ej2-angular-schedule';
import { ButtonAllModule, CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';

import { DatePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { ToolbarAllModule } from '@syncfusion/ej2-angular-navigations';
import { RecoverPassword } from './Identity/RecoverPassword/RecoverPassword';
import { ShearedDataService } from './Lecturer/Service/ShearedData';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    Profile,
    Card,
    CorrespondenceMainForm,
    PersoneListDialog,
    StatementFilter,
    LoginComponent,
    FileUpload,
    ProfileImage,
    SanitizeHtmlPipe,
    CreateViewLetterDocument,
    MarkJounal,
    TimeTable,
    StudyMaterialComponent,
    ViewNewsComponent, SearchStudGroup, ChangePassword,
    LanguagePipe, AlertComponent, RegisterComponent, RecoverPassword
  ],
  entryComponents: [CreateViewLetterDocument, PersoneListDialog, ViewNewsComponent, ChangePassword, RecoverPassword],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ScheduleAllModule,
    ButtonAllModule,
 
    DatePickerAllModule,
    CheckBoxAllModule,
    ToolbarAllModule,
  
      MatFormFieldModule,
      CdkTableModule,
      MatPaginatorModule,
      MatAutocompleteModule,
      MatBadgeModule,
      MatBottomSheetModule,
      MatButtonModule,
      MatButtonToggleModule,
      MatCardModule,
      MatCheckboxModule,
      MatChipsModule,
      MatDatepickerModule,
      MatDialogModule,
      MatDividerModule,
      MatExpansionModule,
      MatGridListModule,
      MatIconModule,
      MatInputModule,
      MatListModule,
      MatMenuModule,
      MatNativeDateModule,
      MatProgressBarModule,
      MatProgressSpinnerModule,
      MatRadioModule,
      MatRippleModule,
      MatSelectModule,
      MatSidenavModule,
      MatSliderModule,
      MatSlideToggleModule,
      MatSnackBarModule,
      MatSortModule,
      MatStepperModule,
      MatTableModule,
      MatTabsModule,
      MatToolbarModule,
      MatTooltipModule,
      MatTreeModule,
      BrowserAnimationsModule,
      ReactiveFormsModule,
      NoopAnimationsModule,
      ChartsModule,
    CdkTreeModule,
   
    CKEditorModule,
  
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'profile', component: Profile, canActivate: [AuthGuard] },
      { path: 'card', component: Card, canActivate: [AuthGuard] },
      { path: 'markJounal', component: MarkJounal, canActivate: [AuthGuard] },
      { path: 'TimeTable', component: TimeTable, canActivate: [AuthGuard] }, 
      { path: 'correspondece', component: CorrespondenceMainForm, canActivate: [AuthGuard] },
      { path: 'StudyMaterial', component: StudyMaterialComponent, canActivate: [AuthGuard] },
      { path: 'login', component: LoginComponent }, 
    ])
  ],
  providers: [ShearedDataService,CardService, GraideService, CorrespondenceService, SharedService, ProfileService, LanguageService, CourseService, StudyMaterialService, CookieService, InformationBoardService, UserService,
    { provide: HTTP_INTERCEPTORS, useClass: AddWithCredentialsInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

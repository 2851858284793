<body
  class="_cs_color-bg--primary-3 _cs_flex _cs_flex-a--start _cs_flex-wrap  _cs_size-w-percent--100 _cs_size-h-min-vh--100">
  <div class="_cs_flex _cs_flex-d--column _cs_size-w-percent--100">
    <app-nav-menu class="_cs_position-fixed _cs_position-left-percent--0 _cs_position-up-percent--0 _cs_size-w-percent--100 _cs_z-5">
    </app-nav-menu>
    <div class="_cs_size-w-percent--100 _cs_size-h-min-vh--100">
      <router-outlet class=""></router-outlet>
    </div>
  </div>
</body>
